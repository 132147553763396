import React from 'react'
import './styles/Skin.css'
import mens from "../raw/img/mens.jpg";
import abs from "../raw/img/abs.jpg";
import acne from "../raw/img/acne.jpg";
import ecz from "../raw/img/ecz.jpg";
import vit from "../raw/img/vit.webp";
import rw from "../raw/img/rw.webp";
import pol from "../raw/img/pol.webp";

export default function Skin(){
    return(
        <div>
            <div className="neuro-main-container">


                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                         SKIN
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Skin</h4>
                        </div>
                    </div>
                 </div>

                {/*-----------------------------------------------------------------*/}
                <div className='d1-symp'>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Abscess
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Abscess</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            An abscess is a place of accumulation of the creamy white, yellow, or greenish fluid, known as pus, surrounded by reddened tissue. It is the result of the body's inflammatory response to a foreign body or a bacterial, viral, parasitic, or fungal infection.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={abs} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                •	signs of inflammation<br/>
•	heat, redness, swelling, and pain over the affected area. <br/>
•	fever and discomfort<br/>

                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Belladonna, Hepar sulphur, Silicea, Mercurius<br/>
                                    RL22<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Acne
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Acne</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Acne or Pimples is a skin condition characterized by whiteheads, blackheads, and inflamed red pimples or "zits."
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={acne} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Hormonal changes related to menstrual periods, pregnancy, birth control pills, or stress<br/>
•	Greasy or oily cosmetic and hair products<br/>
•	Certain drugs (such as steroids, testosterone, estrogen, and phenytoin)<br/>
•	High levels of humidity and sweating<br/>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Blackheads<br/>
•	Crusting of skin eruptions<br/>
•	Cysts<br/>
•	Inflammation around the skin eruptions<br/>
•	Pustules<br/>
•	Redness around the skin eruptions<br/>
•	Scarring of the skin<br/>
•	Whiteheads<br/>
                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    ANTIM CRUDE, ASTERIAS RUBENS, PSORINUM, KALI BROM<br/>
                                    RL-2<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                ECZEMA
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for ECZEMA</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Eczema is a general term for many types of skin inflammation,also known as dermatitis.The most common form of eczema is Atopic dermatitis (some people use these two terms interchangeably). However, there are many different forms of eczema.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={ecz} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <p className='ml-8 text-xl font-bold'>TYPES OF ECZEMA :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    1.	Atopic Dermatitis<br/>
                                    2.	Contact eczema<br/>
                                    3.	Seborrheic eczema<br/>
                                    4.	Nummular eczema<br/>
                                    5.	Neurodermatitis<br/>
                                    6.	Stasis dermatitis<br/>
                                    7.	Dyshidrotic eczema<br/>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    1.	Skin rashes, dry skin<br/>
2.	Redness ,inflammation and swelling<br/>
3.	Itchy dry skin. Itching is always a common symptom. If its not itchy, then probability is its not Eczema<br/>
4.	In Acute and severe form of Atopic Eczema there may be heat and temperature disturbances as the whole body becomes involved<br/>
5.	Cracks on skin, scaly and flaking<br/>
6.	Bleeding and weeping skin, with sticky or watery discharge<br/>
7.	Burning with stinging pain<br/>
8.	Feeling low and down can lead to depression in extreme cases, itching and irritation of skin causing behavioral problems in kids<br/>
9.	Eczema blisters, these may occur in some forms of Eczema<br/>
10.	Eczema scars can occur from perpetual scratching, leaving behind blackish or brownish hyper pigmented spots.<br/>

                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    GRAPHITES, MEZERIUM, HEPAR SULPH, DULCAMARA, SULPHUR
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                VITILIGO
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for VITILIGO</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Vitiligo or Leucoderma is a pigmentation disorder in which melanocytes (the cells that make pigment) in the skin are destroyed. As a result, white patches appear on the skin in different parts of the body. Similar patches also appear on both the mucous membranes (tissues that line the inside of the mouth and nose), and the retina (inner layer of the eyeball). The hair that grows on areas affected by vitiligo sometimes turns white.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={vit} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    1.	Focal pattern -the depigmentation is limited to one or only a few areas<br/>
2.	Segmental pattern - depigmented patches develop on only one side of the body<br/>
3.	Generalized pattern -the most common pattern. Depigmentation occurs symmetrically on both sides of the body.<br/>
                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    ARSENIC SULPH FLAVUM, HYDROCOTYLE ASIATICA, CALCAREA CARB, KALI CARB, LOCOPLEX
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                RINGWORM
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Menstrual Irregularities</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Ringworm is a common skin infection that is caused by a fungus. It is called "ringworm" because it can cause a circular rash (shaped like a ring) that is usually red and itchy. Anyone can get ringworm.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={rw} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <p className='ml-8 text-xl font-bold'>TYPES OF RINGWORM :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    Tinea barbae, Tinea capitis, Tinea cruris, Tinea Corporis, Tinea manus, Tinea pedis, Tinea faciei, Tinea unguium
                                </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                  •	Human to Human - Ringworm often spreads by direct, skin-to-skin contact with an infected person.<br/>
•	Animal to Human - One can contract ringworm by touching an animal with ringworm. Ringworm can spread while petting or grooming dogs or cats. It is also fairly common in cows.<br/>
•	Object to Human - It is possible for ringworm to spread by contact with objects or surfaces that an infected person or animal has recently touched or rubbed against, such as clothing, towels, bedding and linens, combs and brushes.<br/>
•	Soil to Human - In rare cases, ringworm can be spread to humans by contact with infected soil. Infection would most likely occur only from prolonged contact with highly infected soil.<br/>
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    RHUSTOX, SEPIA, SULPHUR, TELLURIUM
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                PSORIASIS
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for PSORIASIS</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Psoriasis is a non contagious skin condition that causes rapid skin cell reproduction resulting in red, dry patches of thickened skin. The common areas affected by psoriasis are the skin of the elbows, knees, and scalp. Psoriasis is seen worldwide, in all races, and both sexes. Psoriasis can be seen in people of any age, from babies to Adults, most commonly patients are first diagnosed in their early adult years.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={pol} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <p className='ml-8 text-xl font-bold'>TYPES OF PSORIASIS:<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                  Plaque psoriasis, Guttate psoriasis, pustular psoriasis, Inverse psoriasis, Psoriatic arthritis, Psoriasis in finger & toe nails (Psoriatic nails).
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Skin patches<br/>
                                    •	Dry or red<br/>
                                    •	Usually covered with silvery, shiny scales<br/>
                                    •	Raised patches of skin<br/>
                                    •	Accompanied by red borders<br/>
                                    •	May crack and become painful, occasional bleeding<br/>
                                    •	Usually discrete, demarcated patches<br/>
                                    •	Usually located on the elbows, knees, trunk, scalp, hands or nails<br/>
                                    •	Skin lesions, including pustules, cracking of skin, skin redness or inflammation<br/>
                                    •	Itching generally more during winters or cold weather<br/>
                                    •	Small scaling dots on the skin (especially in children)<br/>
                                    •	Joint pain or aching, which may be associated with a special type of arthritis (psoriatic arthritis)

                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Bovista, Calcarea carbonica, Lachesis, Lycopodium, Pulsatilla, Sepia
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>




                    {/*-----------------------------------------------------------------*/}
                    <div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE SKIN PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>WARTS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MOLES</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BLISTERS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>SCAR</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>WRINKLES</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>PIGMENTATION DISORDERS</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>ITCHY BOTTOM</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BOILS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>LEPROSY</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>PSORIASIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>SCABIES</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>VITILIGO</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MOUTH ULCER</div>
            </div>
           
              
           
</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>










                
        </div>
        </div>
    )
}
