import React from 'react'
import './styles/Gastro.css'
import gastris from '../raw/img/gastritis.webp'
import pul from '../raw/img/pul.jpg'
import consti from '../raw/img/consti.webp'
import piles from '../raw/img/piles.jpg'
import diar from '../raw/img/diar.jpg'
import dys from '../raw/img/dys.jpg'
import ind from '../raw/img/ind.jpg'

export default function Gastro(){
    return(
        <div>
            <div className="neuro-main-container">
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                           GASTROLOGY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Gastrology</h4>
                        </div>
                    </div>
                 </div>

                {/*-------------------------------------------------------------------------*/}
                <div className='d1-symp'>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>

                                Gastritis
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Gastritis</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Gastritis is defined as the inflammation of the inner lining of the stomach. This may be caused due to various reasons and the condition has become rampant with today's stressful and modernized life
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={gastris} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Infection: Most commonly Bacterial H-Pylori (Helicobacter Pylori) and occasionally due to Fungal, parasitic or viral infection <br/>
                                    •	Drugs: NSAIDs, Steroids, Aspirin, etc<br/>
                                    •	Stress<br/>
                                    •	Smoking<br/>
                                    •	Excessive alcohol consumption<br/>
                                    •	Back-flow of bile into the stomach (bile reflux)<br/>
                                    •	Excess to spicy foods<br/>
                                    •	Radiation<br/>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Pain and/or burning in the upper abdomen or burning pain in the chest region.<br/>
                                    •   Loss of appetite or a sensation of fullness after a few mouthfuls.<br/>
                                    •   Belching (burps), nausea, vomiting, etc.<br/>


                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Carbo vegetabilis, Lycopodium, Nux vomica, Pulsatilla, Arsenicum Album, Cinchona officinalis, Phosphorus.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Peptic Ulcer
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Peptic Ulcer</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Ulcerations (sores) in the lining of the upper part of the digestive tract are known as Peptic Ulcers. The ulcers may exist in the lower part of the food pipe (esophagus), in the stomach, or the initial part of the intestine (duodenum). About 10% of all adults are affected with Peptic ulcers at some time in their life. The incidence of Peptic ulcers is more common in males as compared to females.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={pul} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Emotional stress<br/>
                                    •	Foods (spices, pungent foods, etc)<br/>
                                    •	Overuse of certain drugs, especially NSAIDs (Non-steroidal anti-inflammatory drugs)<br/>
                                    •	Infections (H-Pylori)<br/>
                                    •	Hereditary<br/>
                                    •	Smoking<br/>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Pain in the epigastrium (upper part of the abdomen)<br/>
•	Hunger pain- worsening pain when the stomach is empty<br/>
•	Night pain- wakes the patient from sleep<br/>
•	Heartburn<br/>
•	Water brash<br/>
•	Loss of appetite (anorexia)<br/>
•	Nausea<br/>
•	Haematemesis (vomiting of blood)<br/>
•	Dark or black stools<br/>
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Graphites, Phosphorus
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Constipation
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Constipation</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Constipation is very painful and frustrating. Almost everyone gets constipated at some time during his or her life. Constipation occurs when bowel movements become difficult or less frequent.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={consti} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Difficulty in starting or completing a bowel movement<br/>
                                    •	Infrequent and difficult passage of stool Passing hard stool after prolonged straining in the toilet If one has irritable bowel syndrome, crampy abdominal pain, excessive gas, a sense of bloating, and a change in bowel habits.If one has an intestinal obstruction, nausea, vomiting, no defecation, inability to pass gas Distended abdomen, headaches, loss of appetite Coated (furred) tongue, offensive breath, and bad taste in your mouth<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Nux Vomica, Bryonia, Graphites, Alumina, Silicea, Opium
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Piles or Hemorrhoids
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Piles or Hemorrhoids</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Masses or clumps ("cushions") of tissue within the anal canal that contain blood vessels and the surrounding, supporting tissue made up of muscle and elastic fibers. The anal canal is the last four centimeters through which stool passes as it goes from the rectum to the outside world. The anus is the opening of the anal canal to the outside world.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={piles} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Types of Piles or Hemorrhoids</div>
                            <p className='ml-8 text-xl font-bold'>Internal Hemorrhoids:<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                There are bright red streaks of blood on toilet paper or bright red blood in the toilet bowel after one has a normal bowel movement

Internal hemorrhoids often are small, swollen veins in the wall of the anal canal. But they can be large, sagging veins that bulge out of the anus all the time. They can be painful if they bulge out and are squeezed by the anal muscles. They may be very painful if the blood supply to the hemorrhoid is cut off. If hemorrhoids bulge out, you also may see mucus on the toilet paper or stool.

                               </span>
                            </p><p className='ml-8 text-xl font-bold'>External Hemorrhoids:<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                 External hemorrhoids can bleed, and then the blood pools, causing a hard painful lump. This is called a thrombosed, or clotted, hemorrhoid.
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                   •	Bleeding during bowel movements.<br/>
•	Itching<br/>
•	Rectal pain. It may be painful to clean the anal area<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    RATANHIA, COLLINSONIA, ACID NITRIC, HAMAMELLIS, BLUMIA, Pilax Syrup.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Diarrhea
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Diarrhea</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Diarrhea is the passing of loose or watery stools, several times a day. It is one of the most common health complaints which can range from a mild, temporary condition to a potentially life-threatening one.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={diar} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    1. Viral or bacterial infection<br/>
2. Food poisoning<br/>
3. Food intolerance (for example lactose in milk)<br/>
4. Allergy to certain foods and medicines<br/>
5. Stress.<br/>

                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    1. Stomach cramps<br/>
2. Fever<br/>
3. Abdominal bloating <br/>
4. Nausea<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Arsenic Album, Podophyllum, Veratrum Album, Aloes Socotrina, Cinchona Officinalis.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Dysentery
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Dysentery</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Dysentery is an intestinal infection that causes severe diarrhea with blood. In some cases, mucus may be found in the stool. This usually lasts for 3 to 7 days.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={dys} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Types of Dysentery</div>
                            <p className='ml-8 text-xl font-bold'>
                                <span className='text-blue-900 mt-0 font-bold'>
                                    <ul>
                                        <li>Bacterial dysentery</li>
                                        <li>Amoebic dysentery</li>
                                    </ul>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Abdominal cramps or pain<br/>
                                    •	Nausea<br/>
                                    •	Vomiting<br/>
                                    •	Fever of 100.4°F (38°C) or higher<br/>
                                    •	Dehydration, can become life-threatening if left untreated<br/>
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    MERC COR, Asclepias Tuberosa, Mercurius Solublis, Aloes
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Indigestion
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Indigestion</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Dysentery is an intestinal infection that causes severe diarrhea with blood. In some cases, mucus may be found in the stool. This usually lasts for 3 to 7 days.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={ind} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Early fullness during a meal- we have not eaten much of our meal, but we already feel full and may not be able to finish eating.<br/>
                                    •	Uncomfortable fullness after a meal- Fullness lasts longer than it should.<br/>
                                    •	Discomfort in the upper abdomen-we feels a mild to severe pain in the area between the bottom of our breastbone and our navel.<br/>
                                    •	Burning in the upper abdomen- we feel an uncomfortable heat or burning sensation between the bottom of our breastbone and our navel.<br/>
                                    •	Bloating in the upper abdomen-We feel an uncomfortable sensation of tightness due to a buildup of gas.<br/>
                                    •	Nausea - Feeling as though we want to vomit.

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Antimonium crudum, Aconitum, Ignatia, PHOSPHORUS
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>





                
  {/*------------------------------------------------------------------------------------------------------*/}
                   
               

                    <div className='d-content bg-blue-300 mt-8'>
                   <h2 className='text-2xl text-center text-white p-2  font-bold text-center'> MORE GASTRO PROBLEMS WE TREAT</h2>
                   </div>
  
  
  
            <div className=' mt-4 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-3 h-full w-auto text-center p-4 justify-center align-center'>
           
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>IRRITABLE BOWEL SYNDROME</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>GERD(GASTRO ESOPHAGEL)</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HICCUPS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>STOMACH ULCER</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ANAL FISSURE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ANAL ITCHING</div>
                  </div>
                  <div className="box bg-blue-900 ">
                 <div className='text-xl font-bold text-white p-3 text-center'>ANORECTAL STRICTURE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HERNIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CROHN'S DISEASE</div>
                  </div>
                 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>FOOD POSIONING</div>
                  </div> 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>LOSS OF APETITE (ANOREXIA NERVOSA)</div>
                  </div>
  
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'> ABDOMINAL PAIN</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl  font-bold p-3 text-white text-center'>CYCLIC VOMITING</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>SPLENOMEGALY</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>FISTULA-IN-ANO</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CHRONIC OESOPHAGITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>APPENDICITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CHOLECYSTITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PANCREATITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CHOLERA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>VULVITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HEART BURN</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ULCERATIVE COLITIS</div>
                  </div>
                  
                
                  
                 
                  </div>
                 
 
                  <button
                                  onClick={()=>{
                                      navigator("/book-appointment")
                                  }}
                                  className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                               Consult Now
                              </button>
                
        </div>
        </div>
    )
}
