import React from 'react'
import './styles/Neuro.css'
import d1 from '../raw/img/Meningitis.jpg'
import headache from'../raw/img/headache.jpg'
import alz from '../raw/img/alz.jpg'
import bt from '../raw/img/bt.jpg'
import seiz from '../raw/img/seiz.jpg'
export default function Neuro(){
    return(
        <div>
            <div className="neuro-main-container">


                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                            NEUROLOGY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Neurology</h4>
                        </div>
                    </div>

                 </div>
                <div className='d1-symp'>
                 <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                            MENINGITIS
                        </h3>
                    </div>
                 </div>

                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Meningitis</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>Meningitis is an infection of the coverings around the brain and spinal cord.<br />The infection occurs most often in children, teens, and young adults. Also at  risk are older adults
                   and people who have long-term health problems, such as a weakened immune system.</p>
                 </div>
                 <div className='col-md-7 col-sm-7 col-xs-7 dis1-img'>
                     <img className="d1-img   " src={d1} alt={"disease-img"}/>
                     </div>
                         <h2 className='text-red-600 font-bold text-xl '>What are symptoms of Meningitis?</h2>
                         <p>Symptoms of meningitis, which may appear suddenly, often include<span className='text-blue-900 font-bold'> high fever, severe and persistent headache, stiff neck, nausea, and vomiting.</span> Changes in behavior, such as <span className='text-blue-900 font-bold'>confusion, sleepiness, and difficulty waking up,</span> are important symptoms. In infants, symptoms of meningitis are often much less specific and may include <span className='text-blue-900 font-bold'>irritability or tiredness, poor feeding, and fever

                             </span> Some types of meningitis can be <span className='text-red-500 font-bold'> deadly</span> if not treated promptly. Anyone experiencing symptoms of meningitis should see a doctor immediately.</p>
                               <h3 className='font-bold text-lg types mt-8'>There are two main kinds of Meningitis</h3>


                             <ul>
                            <li className={"type1 text-lg mt-4"}>


                            Bacterial meningitis is not as common but is very serious. It needs to be treated right away to prevent brain damage and death
                            </li>
                            <li className={" type2 text-lg mt-4"}>



                            Viral meningitis is fairly common. It usually does not cause serious illness. In severe cases, it can cause prolonged fever and seizures
                            </li>
                           The two kinds of meningitis share the same symptoms. It is very important to see a doctor if you have symptoms, so that he or she can find out which type you have.


                        </ul>

                        <div className='d1-homeo-treat'>
                            <h1 className=' head-d1-homeo-treat text-2xl text-green-600 font-bold'>Homeopathy Treatment : </h1>
                            <p className='homeo-treat-descp  ml-8'><span className='text-red-600 font-bold'>Homeopathy</span> has a limited role in treating such kind of cases, though initial symptoms of fever, headaches can be effectively controlled
                            homeopathy discerns that the body has within it a controlling and defending force and power .it is only when this fighting power or resistance is disturbed, do we fall ill. The disease is thus not an affection of the parts but a disorder in the patient as a whole .Since one patient differs from another each requires a different medicine based on his individual and mental symptoms.</p>
                        </div>
                          {/*--------------disease2-----------------------------------------*/}


                    <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                        Headache
                        </h3>
                    </div>
                 </div>

                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Headache</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>
                     A headache is a very common condition that causes pain and discomfort in the head, scalp, or neck. It's estimated that 7 in 10 people have at least one headache each year.
                     Headaches can sometimes be mild, but in many cases, they can cause severe pain that makes it difficult to concentrate at work and perform other daily activities. Luckily, most headaches can be managed with medication and lifestyle changes.
                 </p>
                 </div>
                 <div className='col-md-7 col-sm-7 col-xs-7 dis1-img'>
                     <img className="d2-img  mr-8 ml-8" src={headache} alt={"disease-img"}/>
                     </div>

                        <div className='d1-homeo-treat'>
                            <h1 className=' head-d1-homeo-treat text-2xl text-green-600 font-bold'>Headache types: </h1>
                            <p className='homeo-treat-descp  ml-8'>
                                <ul>
                                    <li>Tension headaches</li>
                                    <li>Cluster headaches</li>
                                    <li>Migraine headaches</li>
                                    <li>Rebound headaches</li>
                                    <li>Thunderclap headaches</li>
                                </ul>
                            </p>
                        </div>

                         <div className='d1-homeo-treat'>
                            <h1 className=' head-d1-homeo-treat text-2xl text-green-600 font-bold'>Homeopathy Treatment : </h1>
                             <p className='homeo-treat-descp  ml-8'>
                                 <ul>
                                     <li>Belladonna</li>
                                     <li>Bryonia</li>
                                     <li>Glonoinum</li>
                                     <li>Natrum muriaticum</li>
                                     <li>Nux vomica</li>
                                     <li>Sanguinaria</li>
                                 </ul>
                             </p>
                        </div>
                        {/*-----------------------disease3----------------------------------------------*/}

                        {/*---------------------------disease4----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>

                                Brain Tumor
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Brain Tumor</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>A brain tumor is an abnormal growth of cells within the brain, which can be cancerous or non-cancerous (benign).
                            It is defined as any intracranial tumor created by abnormal and uncontrolled cell division, normally either in the brain itself (neurons, glial cells (astrocytes, dendrocytes, ependymal cells), lymphatic tissue, blood vessels, the cranial nerves (myelin-producing Schwann cells), in the brain envelopes (meninges), skull, pituitary and pineal gland, or spread from cancers primarily located in other organs (metastatic tumors).
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={bt} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms of Brain Tumor , includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Headaches(usually worse in the morning)<br/>
                                    •	Nausea and Vomiting<br/>
                                    •	Changes in speech, vision, or hearing<br/>
                                    •	Problems balancing or walking<br/>
                                    •	Changes in mood, personality, or ability to concentrate<br/>
                                    •	Problems with memory<br/>
                                    •	Muscle jerking or twitching (seizures or convulsions)<br/>
                                    •	Numbness or tingling in the arms or legs<br/>
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>CONIUM MACULATUM, HELLEBORUS NIGER, KALI IODIDE, THUJA OCCIDENTALIS, CALCREA FLOURICA, CALCAREA PHOSPHORICA</p>
                            </div>
                        </div>
                    </div>


                    {/*-----------------------disease3----------------------------------------------*/}
                    <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                        <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                            <h3>

                                Alzheimer
                            </h3>
                        </div>
                    </div>
                    <div className='d1-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Alzheimer</h2>
                    </div>
                    <div className='d1-descp text-lg mr-10 mt-8'>
                        <p>
                            Alzheimer's disease (AD) is a slowly progressive disease of the brain that is characterized by impairment of memory and eventually by disturbances in reasoning, planning, language, and perception. Many scientists believe that Alzheimer's disease results from an increase in the production or accumulation of a specific protein (beta-amyloid protein) in the brain that leads to nerve cell death.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10'>
                            <img className="mr-8 ml-8" src={alz} alt={"disease-img"}/>
                        </div>

                        <div className='d1-homeo-treat'>
                            <h1 className=' head-d1-homeo-treat text-2xl text-green-600 font-bold'>SYMPTOMS OF ALZHEIMER'S DISEASE: </h1>
                            <p className='homeo-treat-descp  ml-8'><br/>
                                •	Multiple strokes<br/>
                                •	Parkinson's disease, a degenerative nerve disorder that causes tremors and mental decline<br/>
                                •	Normal-pressure hydrocephalus, which is excess fluid around the brain<br/>
                                •	Deficiency of B1\B2\B6\pantothenic acid\folic acid\ niacin\biotin\Cobalamin is the general name for vitamin B12, vitamin B12<br/>
                                •	Hypothyroidism, or low levels of thyroid hormone<br/>
                                •	Alcoholism and drug abuse<br/>
                                •	Infectious diseases that cause brain degeneration, such as Creutzfeldt-Jakob disease, viral infections, or fungal infections<br/>
                                •	Poisoning with carbon monoxide or methyl alcohol<br/>
                                •	Depression<br/>
                                •	Medication side effects or drug interactions<br/>
                                •	Brain tumors<br/>
                            </p>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>ALUMINA, CANNABIS INDICA, GINKGO BILOBA, AURUM METALLICUM</p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>

                                EPILEPSY/SEIZURES
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for EPILEPSY/SEIZURES</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>Epilepsy is a central nervous system (neurological) disorder in which brain activity becomes abnormal, causing seizures or periods of unusual behavior, sensations, and sometimes loss of awareness.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={seiz} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Temporary confusion<br/>
                                    •	A staring spell<br/>
                                    •	Uncontrollable jerking movements of the arms and legs<br/>
                                    •	Loss of consciousness or awareness<br/>
                                    •	Psychic symptoms such as fear, anxiety<br/><br/>
                                    <span className={"text-black"}>Focal seizures</span><br/>
                                    •	Focal seizures without loss of consciousness<br/>
                                    •	Focal seizures with impaired awareness<br/><br/>
                                    <span className={"text-black"}>Generalized seizures</span><br/>
                                    •	Absence seizures<br/>
                                    •	Tonic seizures<br/>
                                    •	Atonic seizures<br/>
                                    •	Clonic seizures<br/>
                                    •	Myoclonic seizures<br/>
                                    •	Tonic-clonic seizures<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    CICUTA VIROSA, ARTEMESIA VULGARIS, Stramonium
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>

                                Vertigo
                            </h3>
                        </div>
                    </div>

                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Vertigo</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            EVertigo is not a disease it is a symptom with a sensation that the person or the room is spinning. Vertigo is an illusion of motion and is a problem seen increasingly in people as they age. It is usually temporary and a sign of some other problem or condition. Vertigo is a type of dizziness.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={seiz} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Nausea or vomiting<br/>
                                    •	Sweating<br/>
                                    •	Abnormal eye movements<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    COCCULUS, GELSEMIUM, PULSATILLA, BRYONIA
                                </p>
                            </div>
                        </div>
                    </div>








{/*--------------------------------------------------------------------------------------------------*/}


<div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE NEURO PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BELL'S PALSY</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MIGRAIN</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>SLEEP APNEA SYNDROME</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>TRIGEMINAL NEURALGIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BULMIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>DEMENTIA</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>PARKINSON'S DISEASE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>HYDROCEPHALUS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MULTIPLE SCLEROSIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CEREBRAL PALSY</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>NEURO-FIBRO MATUSIS</div>
            </div>

           
              
           
</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>

















                </div>

        </div>
        </div>
    )
}
