import React from 'react'
import './styles/Liver.css'
import d1 from '../raw/img/gallstones.jpg'
import d2 from '../raw/img/fattyliver.jpg'
import d3 from '../raw/img/NAFLD.jpg'
import d4 from '../raw/img/jaundice.jpg'
import d5 from '../raw/img/hepatitis.jpg'

export default function Liver(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                          LIVER
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Liver</h4>
                        </div>
                    </div>
                  
                 </div>
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Gallstones
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Gallstones</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>  Gallstones are small, pebble-like substances that develop in the gallbladder. The gallbladder is a small, pear-shaped sac located below our liver in the right upper abdomen. Gallstones form when liquid stored in the gallbladder hardens into pieces of stone-like material. The liquid called bile helps the body digest fats
 </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>


                     <p className='ml-8'>Types Types of Gallstones <br/>
                <span className='text-lg text-red-500 font-bold'>  1. Cholesterol stones  </span> :- Cholesterol stones are usually yellow-green and are made primarily of hardened cholesterol. They account for about 80 percent of gallstones<br/><br/>
               <span className='text-lg text-red-500 font-bold'> 2. Pigment stones</span> Pigment stones are small, dark stones made of bilirubin. Gallstones can be as small as a grain of sand or as large as a golf ball. The gallbladder can develop just one large stone, hundreds of tiny stones, or a combination of the two

                     
                               </p>
                            <br/>

                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms  of Gallstones ?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                        •	Steady pain in the right upper abdomen that increases rapidly and lasts from 30 minutes to several hours<br/><br/>
•	Pain in the back between the shoulder blades<br/>
•	pain under the right shoulder<br/>
<br/></span></p>

<p className='ml-8 text-xl font-bold'>People with any of the following symptoms should see a doctor immediately
                         <br/><span className='text-blue-900 mt-0 font-bold'>
                         •	Prolonged pain for more than 5 hours<br/>
•	Nausea and vomiting<br/>
•	Fever even low-grade or chills<br/>
•	Yellowish color of the skin or whites of the eyes<br/>
•	Clay-colored stools<br/>
<br/>


<br/>

                               </span>
                               </p>
                            <br/>
                            


                            
                              
                               
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Calcarea carbonica, Chelidonium, Lycopodium, Natrum sulphuricum, Nux vomica
</p>
                        </div>
                        </div>
                        </div>    
                        </div>
{/*-------------------------------------d2-------------------------------------------------*/}

                  
                 
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Fatty Liver
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Fatty Liver</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>  Fatty liver has large amounts of fats (triglycerides) accumulating within the liver cells. Excess aggregation of fats within the small hepatocytes swells them up and occasionally the whole liver is enlarged enough for feeling it.
Fatty liver can be a harbinger of more troublesome conditions. Steatosis simply means a collection of fat vacuoles within the liver cells (hepatocytes).

 </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>


                    
                     
                                                          

                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms  of Fatty Liver ?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	Malaise - or a feeling of severe discomfort, making the person want to rest,<br/>
•	Fatigue - even with moderate exertion<br/>
•	Occasionally the liver may be painful under pressure<br/>
•	Yellowish discoloration of the skin (jaundice), dark-colored urine.<br/>
•	Weight loss<br/>
•	Nausea and vomiting<br/>
•	Loss of appetite<br/>
•	Abdominal distension (due to increased fluid in the abdomen - ascites)<br/>
•	Easy bleeds from small trauma<br/>
•	Blood doesn't clot soon enough<br/>
•	Flapping tremors of the hands (asterixis)<br/>
•	Itching in the hands and legs which gradually spread all over the body<br/>
•	Veins in the legs, and abdomen, seem engorged and distended<br/>
•	Giddiness<br/>
<br/>
<br/></span></p>

                       <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment  :</div>
                            <p className='   text-xl font-bold '>Lycopodium, Phosphorus, Calcarea carb
</p>
                        </div>
                        </div>
                        </div>
                        
                           

{/*-------------------------------------d3-------------------------------------------------*/}
<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Non-alcoholic fatty liver disease (NAFLD)
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Non-alcoholic fatty liver disease (NAFLD)</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Non-alcoholic fatty liver disease (NAFLD) is the term for a range of conditions 
                     caused by a build-up of fat in the liver. It's usually seen in people who are overweight
                      or obese.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d3} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>


                     <p className='ml-8 '>Stages: <br/>NAFLD develops in 4 main stages<br/>
                <span className='text-lg text-red-500 font-bold'>  1.	simple fatty liver (steatosis)  </span> :- a largely harmless build-up of fat in the liver cells that may only be diagnosed during tests carried out for another reason<br/>
               <span className='text-lg text-red-500 font-bold'>   2. 	non-alcoholic steatohepatitis (NASH)</span>  :- a more serious form of NAFLD, where the liver has become inflamed; this is estimated to affect up to 5% of the UK population.<br/>
               <span className='text-lg text-red-500 font-bold'>   3.	fibrosis </span> where persistent inflammation causes scar tissue around the liver and nearby blood vessels, but the liver is still able to function normally.<br/> 
               
               
               <span className='text-lg text-red-500 font-bold'>   4.	cirrhosis </span>  :-  the most severe stage, occurring after years of inflammation, where the liver shrinks and becomes scarred and lumpy; this damage is permanent and can lead to liver failure (where your liver stops working properly) and liver cancer.<br/>
                               </p>
                            <br/>

                         <div className='text-red-600 font-bold text-3xl  m-auto text-left p-3 '> Symptoms :</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	a dull or aching pain in the top right of the tummy (over the lower right side of the ribs)<br/>
•	fatigue (extreme tiredness)<br/>
•	unexplained weight loss<br/>
•	weakness<br/>
<br/>
<br/></span></p>
                              <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Chelidonium, Lycopodium, Phosphorus, Calcarea carb, Nux Vomica</p>
                        </div>
                        </div>
                        </div>   

{/*-----------------------------------------------------------d4-------------------------------*/}


<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Jaundice
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Jaundice</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>  Jaundice" is the medical term that describes yellowing of the skin and eyes. Jaundice itself is not a disease, but it is a symptom of several possible underlying illnesses. Jaundice forms when there is too much bilirubin in your system. Bilirubin is a yellow pigment that is created by the breakdown of dead red blood cells in the liver. Normally, the liver gets rid of bilirubin along with old red blood cells.
                        <br/>Jaundice may indicate a serious problem with the function of your red blood cells, liver, gallbladder, or pancreas.
                    </p>
                 </div>

              <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                  <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                       <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms  of Jaundice?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	Yellow-tinted skin and eyes<br/>
                         •	excessive fatigue and vomiting<br/>
                        
                        </span>
                        </p>



                       <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment  :</div>
                            <p className='   text-xl font-bold '>Nux vomica, Phosphorous, Carduus Marianus, Chelidonium</p>
                        </div>
                    </div>
                </div>
                        
                        
                           

            {/*-------------------------------------d5-------------------------------------------------*/}


            <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Hepatitis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Hepatitis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Hepatitis is a disease characterized by inflammation of and injury to the liver. 
                     Hepatitis has many causes, including misuse of alcohol and drugs, but viruses are the most common cause.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d5} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                    

                     <div className='text-red-600 font-bold text-3xl  m-auto text-left p-3 '> There are five types of viral hepatitis :</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                         (1) Hepatitis A,<br/>
(2) Hepatitis B,<br/>
(3) Hepatitis C,<br/>
(4) Hepatitis D, and<br/>
(5) Hepatitis E.<br/>

<br/>
<br/></span></p>



                     <p className='ml-8 '>
                <span className='text-lg text-red-500 font-bold'>  1.	Hepatitis A  </span>  is a highly contagious disease, but it is rarely fatal. It is also called infectious hepatitis. Hepatitis A is extremely common in developing nations. Outbreaks often occur due to unsanitary conditions, such as contamination of food or the water supply. The serum gamma globulin can prevent hepatitis A if given before or soon after exposure to the virus. A vaccine that prevents hepatitis A is available. It is recommended for high-risk groups, including international travelers and some military personnel.<br/><br/>
               <span className='text-lg text-red-500 font-bold'>   2. 	Hepatitis B</span>   is the best-known form of viral hepatitis. It can be severe and often develops into chronic hepatitis and cirrhosis. Hepatitis B is spread by close personal or sexual contact with an infected person, or by exposure to infected blood. It once was commonly transmitted through transfusions of contaminated blood. However, tests that detect the virus in blood have largely eliminated this danger. A vaccine that prevents hepatitis B is available, and public health experts recommend that all children be vaccinated against the disease. Doctors use a drug called alpha interferon to treat chronic hepatitis B.<br/>
           <br/>  <span className='text-lg text-red-500 font-bold'>3.	Hepatitis C </span> is the most common cause of chronic hepatitis and a major cause of cirrhosis. Experts think many cases result from using contaminated needles for injecting illegal drugs, tattooing, or body piercing. Hepatitis C often leads to chronic hepatitis, cirrhosis, or liver failure. Since a blood test to detect this virus became available in 1990, infection is rarely transmitted by blood transfusions. Hepatitis C is treated with drugs called alpha interferons <br/> 
               
               
            <br/> <span className='text-lg text-red-500 font-bold'>4.	Hepatitis D </span>   is the most serious and also the rarest form of viral hepatitis. It only infects people who also have hepatitis B. Many cases of hepatitis D are fatal, and most chronic cases lead to cirrhosis. Hepatitis D most commonly occurs among intravenous drug users, who can catch the infection by sharing hypodermic needles  <br/>
                               
            <br/> <span className='text-lg text-red-500 font-bold'>5.	Hepatitis E </span> often occurs in epidemics that can be linked to poor hygiene and contaminated water. It is particularly likely to lead to serious illness in pregnant women. The disease has been reported almost exclusively in developing countries <br/>
                               </p>
                            <br/>

                        
                             <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Bryonia Alba, Chelidonium Majus, Hepar Sulph</p>
                        </div>
                        </div>
                        </div>   

{/*---------------------------------------------------------------------------------------------------*/}

<div className='d-content bg-blue-300 mt-8'>
                   <h2 className='text-2xl text-center text-white p-2  font-bold text-center'> MORE LIVER PROBLEMS WE TREAT</h2>
                   </div>


                   <div className=' mt-4 grid  grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
           
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                <div className='text-xl font-bold p-3 text-white text-center'>CIRRHOSIS</div>
                 </div>
                 <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                <div className='text-xl font-bold p-3 text-white text-center'>LYMPHATIC CONDITION</div>
                 </div>
                 <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                <div className='text-xl font-bold p-3 text-white text-center'>WILSON DISEASE</div>
                 </div>
                 <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                <div className='text-xl font-bold p-3 text-white text-center'>LIVER ABSCESS</div>
                 </div>
                 <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                <div className='text-xl font-bold p-3 text-white text-center'>HEPATORENAL SYNDROME</div>
                 </div>
                 </div>
                

                 <button
                                  onClick={()=>{
                                      navigator("/book-appointment")
                                  }}
                                  className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                               Consult Now
                              </button>












        
          </div>
    )
}