import p1 from "../../raw/img/patients/p1.png"
import p2 from "../../raw/img/patients/p2.png"
import p3 from "../../raw/img/patients/p3.png"
import p4 from "../../raw/img/patients/p4.png"
import p5 from "../../raw/img/patients/p5.png"
import p6 from "../../raw/img/patients/p6.jpg"
import p7 from "../../raw/img/patients/p7.jpg"
import p8 from "../../raw/img/patients/p8.png"
import p9 from "../../raw/img/patients/p9.png"
import p10 from "../../raw/img/patients/p10.png"

export const patientsList = [
    {id:"nIGHQRgt1Mk", poster:p1},
    {id:"UVN3Geg99zI", poster:p2},
    {id:"9yC6s8b9-KE", poster:p3},
    {id:"czluVgNrspI", poster:p4},
    {id:"4en40LnHIs8", poster:p5},
    {id:"V6i6ISuwio4", poster:p6},
    {id:"jTX2jQvUsv8", poster:p7},
    {id:"3E1Q5DaYzMQ", poster:p8},
    {id:"dgmj3_SQoNM", poster:p9},
    {id:"sRYXAMWRaFk", poster:p10},
]
