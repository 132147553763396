import React from 'react'
import './styles/common.css'
import { useNavigate } from 'react-router';




export default function Male1(){
    const navigator = useNavigate()
    return(

    
        <div>



        <div className="neuro-main-container">
                  
                  
                  <div className='neuro-banner about-banner container-bn p-0'>
                      <div className='neuro-banner-content'>
                          <h3>
                              
                           MALE PROBLEMS
                          </h3>
                      </div>
                      <div className='banner-ls container-fluid mt-0'>
                          <div className='container'>
                              <ol className='cont-list'>
                                  <li className='font-bold text-white '>
                                      <a href='./src/pages/Home.js'>
                                          Home
                                      </a>
                                  </li>
                              </ol>
                              <h4 className='text-white font-bold '>Male problems</h4>
                          </div>
                      </div>
                    
                   </div>
  {/*------------------------------------disease list--------------------------*/}
  
  
  <div className='  d-banner container-bn-d  p-0 mt-8'>
                      <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                          <h3>
                              
                        Male Problems
                          </h3>
                      </div>
                   </div>
  
  
  
  <div className='d-content bg-blue-300 mt-8'>
                   <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MALE PROBLEMS WE TREAT</h2>
                   </div>
  
  
  
            <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
           
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PROSTATE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>AZOOSPERMIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HYDROCELE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>EARLY EJACULATION</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>SYPHILIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>TESTOSTERONE</div>
                  </div>
                  <div className="box bg-blue-900 ">
                 <div className='text-xl font-bold text-white p-3 text-center'>GENITAL WARTS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>EPIDIDYMITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ERECTILE DYSFUNCTION</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>BENIGN PROSTATIC HYPERPLASIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PHIMOSIS</div>
                  </div> 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>BALANITIS</div>
                  </div>
  
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>NOCTURNAL EMISSIONS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl  font-bold p-3 text-white text-center'>SPERMATORRHOEA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>OLIGOSPERMIA/LOW SPERM</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PROSTATITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>GYNECOMASTIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CRYPTORCHIDISM</div>
                  </div>
                 
                    
                 
  </div>
                  <button
                                  onClick={()=>{
                                      navigator("/book-appointment")
                                  }}
                                  className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                               Consult Now
                              </button>
  
  </div>
  
      </div>
  
  
      )}
