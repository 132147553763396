import React from 'react'
import './styles/Infertility.css'
import Infertility2 from '../raw/img/infertility3.png'
import Infertility3 from '../raw/img/infertility2.jpg'




export default function Infertility(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                         INFERTILITY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>INFERTILITY</h4>
                        </div>
                    </div>
                  
                 </div>



                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Infertility
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl ml-8  mt-8'>Homeopathy Treatment for Infertility</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Infertility is a disease of the male or female reproductive system defined by the failure to achieve a pregnancy after 12 months or more of regular unprotected sexual intercourse.
                          </p>

                 </div>
                 <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={Infertility2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Infertility ?</div>
                         <p className='ml-8'>Symptoms of Infertility: <br/><span className='text-blue-900 font-bold'>
                         The main symptom of infertility is not getting pregnant. There may be no other obvious symptoms. Sometimes, a woman with infertility may have irregular or absent menstrual periods. In some cases, a man with infertility may have some signs of hormonal problems.</span> </p>
                            <br/>
                     </div>
                    </div>

                            
                            <div className='d-content bg-blue-300 '>
                    <h2 className='text-3xl ml-8  mt-8'>Causes Of Infertility</h2>
                      </div>
                      <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                            <div className=' m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl   mt-4  '>Causes of male infertility</div>
                         <p className='ml-8'> <br/><span className='text-blue-900 font-bold'>
                         •	Abnormal sperm production or function <br/>
                         •	Problems with the delivery of sperm<br/>
                         •	Overexposure to certain environmental factors<br/>
                         •	Damage related to cancer and its treatment<br/></span> </p>
                    </div>



                            <div className=' m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl   mt-4  '>Causes of female infertility</div>
                         <p className='ml-8'> <br/><span className='text-blue-900 font-bold'>
                         	•	Ovulation disorders <br/>
                             •	Uterine or cervical abnormalities<br/>
                             •	Fallopian tube damage or blockage<br/>
                             •	Endometriosis<br/>
                             	•	Primary ovarian insufficiency (early menopause) <br/>
                             	•	Pelvic adhesions<br/>
                             	•	Cancer and its treatment<br/></span> </p>
                           </div> 

                        </div>
                           

                               
                            <div className='d-content bg-green-400 '>
                    <h2 className='text-3xl ml-8 text-black p-3 font-bold mt-8'>Homeopathy Treatment For Infertility</h2>
                      </div>
                            
                      <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                          <div>
                            <div className='text-red-600 font-bold text-xl    mt-4 '>For Infertility in  males with:</div>
                         <div className='ml-8 mt-0'> <br/><span className='text-blue-900 font-bold'>
                         	•	Erectile Dysfunction - Agnus Castus, Caladium, Selenium <br/>
                             •	Orchitis - Coium tops the chart of medicines<br/>
                             •	Wasting of Testes - Sabal Serrulata<br/>
                            </span> </div> </div>
                                
                                 
                                <div>
                                 <div className='text-red-600 font-bold text-xl   mt-4  '>For Infertility in females due to :</div>
                         <div className='ml-8 mt-0'> <br/><span className='text-blue-900 font-bold'>
                         	•	Acid Vaginal Discharges - Borax and Natrum Phos <br/>
                             •	Profuse or Prolonged Periods (menorrhagia) - Calcarea Carb and Aletris Farinosa<br/>
                             •Short, Scanty Periods - Pulsatilla and Sepia<br/>
                             •Decreased Sexual Desire- Agnus Castus and Sepia <br/>
                             	•	Non Retention of Sperms- Natrum Carb. <br/>
                             	</span> </div>
                                 </div></div>


                    
                      </div>
                      <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto w-auto h-80" src={Infertility3} alt={"disease-img"}/>
                     </div>
         </div>
                                 

                                 
                           
                        
                                   
                
        
        
        
                          

                           
               
        
          
        
         





        
    )
}