import {getFirestore, addDoc, collection} from "firebase/firestore"

//ToDo Shift this to REST API
/**
 * Uploads appointment data to Firestore
 * @param data
 * @returns {Promise<unknown>}
 */
export function uploadAppointmentToFirestore(data= {name:"",
                                           age:0,
                                           gender:"",
                                           phone:"",
                                           email:"",
                                           address:"",
                                           issue:"",
                                           status:0}) {

    const database = getFirestore()
    return new Promise((resolve, reject)=>{
        addDoc(collection(database,"appointment-request"), data)
            .then((result)=>{
                resolve(result)
            }).catch((err)=>{
            reject(err)
        })
    })
}
