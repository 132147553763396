import React, {useEffect, useState} from "react"
import {Button, Dialog} from "@mui/material";
import {Close} from "@mui/icons-material";
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed";
import {patientsList} from "./PatientsData";

export default function PatientsGrid(){

    const [videoDialogVisible, setVideoDialogVisible] = useState(false)
    const [videoUID, setVideoUID] = useState("")

    return(
        <div className={"p-4"}>
            <div className={"grid md:grid-cols-3 grid-cols-1 grid-flow-cols gap-8"}>
                {
                    patientsList.map((item)=>(
                        <div className={"bg-white rounded-xl shadow-xl p-2 hover:bg-blue-500 cursor-pointer"}
                             onClick={_=>{
                                 setVideoUID(item.id)
                                 setVideoDialogVisible(true)
                             }}>
                            <img className={"hover:skew-y-12 hover:opacity-70"} src={item.poster} alt={"patient image"}/>
                        </div>
                    ))
                }
            </div>
            <Dialog
                fullScreen={true}
                maxWidth={"md"}
                open={videoDialogVisible}
                onClose = {()=>{
                    setVideoDialogVisible(false)}}>
                <Button
                    color={"error"}
                    startIcon={<Close/>}
                    onClick={()=>{
                        setVideoDialogVisible(false)
                    }}
                >Close</Button>
                <YoutubeEmbed embedId={videoUID}/>
            </Dialog>
        </div>
    )
}
