import shubhamroy from "../raw/img/Docteam.png"
import shubhamroy2 from "../raw/img/Docteam1.png"

import about from "../raw/img/about.jpg"
import shape from "../raw/img/about-shape.png"
import service from "../raw/img/neurology.jpg"
import service1 from "../raw/img/liver.jpg"
import service2 from "../raw/img/gynecologyjpg.jpg"
import service3 from "../raw/img/skin.jpg"
import service4 from "../raw/img/gastro.jpg"
import service8 from "../raw/img/child.jpg"
import review1  from "../raw/img/skin2.jpg"
import review2  from "../raw/img/SKIN1.jpg"
import videocover from "../raw/img/videocoverimg.jpg"
import team from "../raw/img/Team1.jpg"
import team2 from "../raw/img/Team2.jpeg"
import team3 from "../raw/img/team3.jpeg"
import team4 from "../raw/img/team4.jpeg"
import team5 from "../raw/img/team5.jpg"
import team6 from "../raw/img/team6.jpg"
import team7 from "../raw/img/team7.jpeg"
import hf from "../raw/img/hf.png"
import alr from "../raw/img/allergy.png"
import piles from "../raw/img/piles.png"

import family from "../raw/img/family2.p.png"
import gulabsha from "../raw/img/gulabsha.jpg"

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation, Pagination, Autoplay, Scrollbar, A11y} from 'swiper';
import SwiperCore from 'swiper';


import React, {useEffect} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faUsers,
    faCertificate,
    faCheckCircle,  faUserMd, faLaptop, faPlayCircle, faEnvelope, faMobileAlt, faLocationArrow,
} from '@fortawesome/free-solid-svg-icons'

import styles from './styles/Home.css'
import './item'
import {Component, useState} from "react";
import Item from "./item";
import {Button, Dialog} from "@mui/material";
import YoutubeEmbed from "../components/YoutubeEmbed/YoutubeEmbed";

import { useNavigate } from 'react-router';
import {Carousel} from "react-responsive-carousel";
import {Close, Style} from "@mui/icons-material";
import {patientsList} from "../components/Patients/PatientsData";









    export default function Home(){

        SwiperCore.use([Autoplay])

        const navigator = useNavigate()

        const [isMobileView, setIsMobileView] = useState((((window.innerWidth > 0) ? window.innerWidth : window.screen.width) <= 700))

        const [videoUID, setVideoUID] = useState("")
        const [videoDialogVisible, setVideoDialogVisible] = useState(false)
        const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 1 },
            { width: 768, itemsToShow: 1 },
            { width: 1200, itemsToShow: 1 },

        ];

        useEffect(()=>{
            window.matchMedia("(max-width: 700px)").addEventListener("change", (e)=>{
                console.log(e.matches, (window.innerWidth > 0) ? window.innerWidth : window.screen.width)
                if(e.matches){
                        setIsMobileView(true)
                }else{
                        setIsMobileView(false)
                }
            })
            return()=>{window.scrollTo(0, 0)}
        },[])

      
         return (

                
                   
                  
       
                    <div>
                      
                      <Swiper
                   modules={[Pagination,Autoplay]}
                   slidesPerView={1}

                   autoplay={{delay: 2000}}
                   pagination={{clickable: true}}
                   >
                       <SwiperSlide>
                    <div style={{height:"700px"}} className={" roy grid grid-cols-1 lg:grid-cols-2 text-center justify-center align-center bg-gradient-to-b lg:bg-gradient-to-r from-blue-600 "}>

                        <div className="bg-white-200 text-center lg:p-10 lg:ml-12 md:ml-10 sm:bg-transparent  sm:text-left">

                                <h1 className="lg:mt-32 mt-10 font-bold text-white text-5xl mb-3 lg:ml-6 ml-4 mr-4">Your Health is Our
                                            Priority </h1>

                                        <p className={"mt-4 text-base text-xl text-white lg:ml-6 md:ml-4 ml-4 mr-4"}>"Homeopathy cures agreater percentage of cases than any other method of treatment. Homeopathy is the latest and refined method of treating patients economically and non-violently" </p>
                                         <p className={" font-bold text-xl text-yellow-200 ml-6 "}>-Mahatma Gandhi</p>
                            <button
                                   onClick={()=>{
                                    navigator("/book-appointment")
                                   }}
                                    className={"mt-6 ml-6 sm:px-8  px-6  bg-white rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-blue-800 hover:bg-blue-800  hover:text-white   text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                         Book Now
                                           </button>
                           <button
                                          onClick={()=>{
                                              navigator("/about")
                                                }}
                                        className={"mt-6 ml-6 px-6   bg-transparent outline-white  rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white hover:bg-white hover:text-blue-800 text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                          Learn more
                                         </button>
                                            </div>

                      

                       <div className={"flex flex-wrap justify-center  md:content-end content-top"}>
                      <img className="shubhamroy-img object-contain" src={shubhamroy} alt={"home"}/>
                           
                        </div> 
            </div></SwiperSlide>

                    {/*-------------------------2ndslide----------------------------------*/}



                      <SwiperSlide>
                    <div style={{height:"700px"}} className={" roy grid grid-cols-1 lg:grid-cols-2 gap-4 text-center justify-center align-center bg-gray-500"}>

                    <div className="bg-white-200 text-center lg:p-10 lg:ml-12 md:ml-10 sm:bg-transparent  sm:text-left">
                    <h1 className="lg:mt-32 mt-10 font-bold text-white text-3xl mb-3 lg:ml-6 sm:ml-4 sm:mr-4 ml-4 mr-4">
                                          Suffering from Hair Loss for a very long time?
                                         </h1>

                                  <p className={" ml-4 mr-4 text-base text-md text-white lg:ml-6 md:ml-4 sm:ml-4 sm:mr-4 ml-2 mr-2"}> Don't worry at Dr Roy's Homoeopathy ,we have well qualified and experienced team of Trichologist(Hair specialist)


                       who will guide you with all the solutions to your problems.  <br/></p>
                       <p className={" font-bold text-lg text-white ml-6"}> Great hair doesn't happen by chance, it happens by appointment!
                          </p>
                               <div className="  grid grid-cols-2 lg:grid-cols-3 ">
                                 <button
                                         onClick={()=>{
                                    navigator("/book-appointment")
                                           }}
                                       className={"mt-4 ml-6 px-8 bg-white rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-blue-800 hover:bg-blue-800  hover:text-white   text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                           Consult Now
                                              </button>
                                           
                           <button
                                          onClick={()=>{
                                              navigator("/hair")
                                                }}
                                        className={"mt-6 ml-6 px-6   bg-transparent outline-white  rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white hover:bg-white hover:text-blue-800 text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                          Know more
                                         </button>
                                            </div>
                                            </div>

                                           <div className={"flex flex-wrap justify-center  md:content-end content-top"}>
                           <img className="shubhamroy-img object-contain" src={hf} alt={"home"}/>
                       </div>
                                     </div>      </SwiperSlide>


{/*-------------------------3rd slide--------------------------------------------------------------------------------------------------*/}






                <SwiperSlide>
                    <div style={{height:"700px"}} className={"roy slide-allergy flex flex-row grid grid-cols-1 lg:grid-cols-2 gap-4 h-screen text-center justify-center align-center "}>

                            <div className="overlay  text-center p-10 lg:ml-12 md:ml-10 sm:bg-transparent sm:text-left">

                                  <h1 className="lg:mt-32 mt-2 font-bold text-white text-3xl mb-3 lg:ml-6 sm:ml-4 sm:mr-4">No more Allergies </h1>

                                  <p className={" text-base text-md text-white lg:ml-6 md:ml-4 sm:ml-4 sm:mr-4"}>Yeah it's true with Dr Roy's Homoeopathy say goodbye to allergies.
                                      We have treated 100+ patients with allergy problems and got 99% positive results.
                         <br/></p>
                         <p className={" font-bold text-xl text-white ml-6"}>Book your appointment today with our well experienced doctors.</p>

                                 

                                 <button
                                         onClick={()=>{
                                    navigator("/book-appointment")
                                           }}
                                       className={"mt-4 ml-6 px-8  bg-white rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-blue-800 hover:bg-blue-800  hover:text-white   text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                           Consult Now
                                              </button>
                                           </div>

                                           <div className={"flex flex-wrap justify-center  md:content-end content-top"}>
                           <img className="shubhamroy-img object-contain" src={alr} alt={"home"}/>
                       </div>


                                     </div>      </SwiperSlide>








{/*-------------------------4th slide-----------------------------------------------------------------------------------------------------*/}





                <SwiperSlide>
                    <div style={{height:"700px"}} className={"slide-4 roy flex flex-row grid grid-cols-1 lg:grid-cols-2 gap-4 text-center justify-center align-center "}>

                            <div className="overlay  text-c  text-center p-10 lg:ml-12 md:ml-10 sm:bg-transparent sm:text-left p-10 lg:ml-12 md:ml-10 sm:bg-transparent sm:text-left">

                                  <h1 className="lg:mt-32 mt-10 font-bold text-white text-3xl mb-3 lg:ml-6 ml-4 mr-4 ">Piles - no more pain & bleeding
                                    </h1>

                                  <p className={" text-base text-sm text-white lg:ml-6 md:ml-4 ml-4 mr-4"}>Whether you have Hemorrhoids, Bleeding piles, or Fissure and Fistula,  connect with us at Dr Roy's Homoeopathy.
                                  
                         </p>
                         <p className={" font-bold text-lg text-white ml-6"}>Book your appointment today with our well experienced doctors.</p>
                       
                       <div className="grid  grid-cols-2 lg:grid-cols-3 ">
                         <button
                                         onClick={()=>{
                                    navigator("/book-appointment")
                                           }}
                                       className={"mt-4 ml-6 px-6 bg-white rounded-md cursor-pointer shadow-lg p-4 m-2 font-bold text-blue-800 hover:bg-blue-800  hover:text-white   text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                           Consult Now
                                              </button>
                                           
                           <button
                                          onClick={()=>{
                                              navigator("/gastro")
                                                }}
                                        className={"mt-6 ml-6 px-6   bg-transparent outline-white  rounded-md cursor-pointer shadow-lg p-4 m-2 font-bold text-white hover:bg-white hover:text-blue-800 text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                          Know more
                                         </button>
                                            </div>
                                            </div>

                                           <div className={"flex piles flex-wrap justify-center  md:content-end content-top"}>
                           <img className="shubhamroy-img object-contain " src={piles} alt={"home"}/>
                         
                       </div>
                                     </div></SwiperSlide>















 {/*-------------------------5th slide--------------------------------------------------------------------------------------------------*/}







                <SwiperSlide>
                    <div style={{height:"700px"}} className={"family roy flex flex-row grid grid-cols-1 lg:grid-cols-2 gap-4 h-full text-center justify-center align-center "}>

                            <div className="overlay  text-center p-10 lg:ml-12 md:ml-10 sm:bg-transparent sm:text-left">

                                  <h1 className="lg:mt-32 mt-10 font-bold text-white text-3xl mb-3 lg:ml-6 ml-4 mr-4 ">We care for you & your family
                                        </h1>

                                  <p className={"text-base text-md text-white lg:ml-6 md:ml-4 ml-4 mr-4 "}>We provide care to people of all ages. At Dr Roy's Homoeopaty we treat chronic conditions, evaluate symptoms, offer preventative care.
                        </p>
                               <p className={" font-bold text-lg text-white ml-6"}> Book your appointment today with our well experienced doctors.  </p>
                                <div className="grid grid-cols-2 lg:grid-cols-3  ">
                               <button
                                         onClick={()=>{
                                    navigator("/book-appointment")
                                           }}
                                       className={"mt-4 ml-6 px-6 bg-white rounded-md cursor-pointer shadow-lg p-4 m-2 font-bold text-blue-800 hover:bg-blue-800  hover:text-white   text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                           Consult Now
                                              </button>
                                           
                           <button
                                          onClick={()=>{
                                              navigator("/child")
                                                }}
                                        className={"mt-4 ml-6 px-6  bg-transparent outline-white  rounded-md cursor-pointer shadow-lg p-4 m-2 font-bold text-white hover:bg-white hover:text-blue-800 text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                          Know more
                                         </button>
                                            </div>
                                            </div>

                                           <div className={" family-img flex  flex-wrap justify-center  md:content-end content-top"}>
                           
                           <img className="shubhamroy-img object-contain " src={family} alt={"home"}/>
                       </div>


                                     </div>      </SwiperSlide></Swiper>


           {/*-----------------------upper service section starts here--------------------------*/}              

                    <div className={"w-full grid grid-cols-1 md:grid-cols-3 bg-white public"}>
                          <div className={"public-services cont"}>
                            <FontAwesomeIcon className={"public-icon1 text-blue-500 text-5xl pt-3"}
                                                     icon={faUsers}/>
                                                       <div className={"container--content text-blue-500 mt-3.5 pb-3"}>
                                        <h1 className={"text-3xl font-bold"}>5000+</h1>
                                        <h2 className={"text-xl"}>Happy Patients</h2>
                                    </div>
                                    <div className={"text-center container--content--button btn container--content--link "}

                                        onClick={()=>{navigator("/patients")}}>Learn More

                                    </div>
                                    </div>

                                    <div className={"public-services container2 cont"}>
                                    <FontAwesomeIcon className={"public-icon1 text-blue-500 text-5xl pt-3"}
                                                     icon={faLaptop}/>
                                    <div className={"container--content text-blue-500 mt-3.5  pb-3"}>
                                        <h1 className={"text-3xl font-bold"}>Online Consultation</h1>
                                        <h2 className={"text-xl"}>24/7</h2>
                                    </div>
                                    <div className={"text-center container--content--button btn container--content--link "}

                                        onClick={()=>{navigator("/book-appointment")}}>Learn More

                                    </div>
                                    </div>

                                    <div className={"public-services container3 cont"}>
                                    <FontAwesomeIcon className={"public-icon1 text-blue-500 text-5xl pt-3"}
                                                     icon={faCertificate}/>
                                    <div className={"container--content text-blue-500 mt-3.5  pb-3"}>
                                        <h1 className={"text-3xl font-bold"}>40+</h1>
                                        <h2 className={"text-xl"}>Years of experience</h2>
                                    </div>
                                    <div className={"text-center container--content--button btn  container--content--link "}

                                        onClick={()=>{navigator("/about")}}>Learn More

                                    </div>
                                </div>


                         </div>






            {/*-------------------------------upper section ends here----------------------->*/}
        {/*---------------------------------About section starts here--------------------------->*/}
                <div className={"flex flex-row grid grid-cols-1 md:grid-cols-2 gap-4  text-center justify-center align-center mt-44 ml-8"}>
                    <div className={"about bg-blue-800 rounded-lg w-auto h-96 md:ml-8 "}>

                        <img className="about-img md:ml-24 mt-10 w-auto rounded-lg w-34" src={about}
                             alt={"about"}/>
                    </div>


                    <div className="overlay bg-white-200 text-center md:p-10 sm:bg-transparent sm:text-left md:ml-32">
                        <img className={"about-shape"} src={shape}/>
                        <h1 className="mt-20 font-bold text-blue-800 text-5xl mb-3  ">About us
                        </h1>

                        <p className={"mt-4 text-base  text-black whitespace-pre-wrap "}>
                        Dr. Roy's homeopathy clinic is a well-renowned clinic that's been rendering its valuable services since the year 1950. Our founder, Dr. Amal Chandra Roy, started this clinic with a mission to ensure accessible and affordable Quality Homeo Health Care by Compassionate medical Professionals to all.

                        Homeopathy is the latest of treating patients economically and non-violently" </p>
                        <ul>
                            <li className={"text-xl font-bold mb-3"}>
                                <FontAwesomeIcon className={"text-blue-400 text-2xl mt-10 mr-2 "}
                                                 icon={faCheckCircle}/>
                                Browse Our Website
                            </li>
                            <li className={"text-xl font-bold mb-3"}>
                                <FontAwesomeIcon className={"text-blue-400 text-2xl mr-2 "} icon={faCheckCircle}/>

                                Choose Our Service
                            </li>
                            <li className={"text-xl font-bold mb-3"}>
                                <FontAwesomeIcon className={"text-blue-400 text-2xl mr-2 "} icon={faCheckCircle}/>
                                Send Message
                            </li>

                        </ul>

                        <button
                            className={"mt-4 ml-2  px-12 bg-blue-800 rounded cursor-pointer shadow-lg p-4 m-4 font-bold text-white hover:bg-black  hover:text-white  text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}
                            onClick={()=>{navigator("/about")}}>Know More
                        </button>

                    </div>
                </div>
                {/*-------------------------------About section ends here---------------------------------------------------*/}

                {/*----------------------------- Service section starts here------------------------------------------------- */}

                <section className={"services "}>
                    <div className={"services-header-container"}>
                        <div className={"services-header"}>
                            <h1 className={"services-header-head text-5xl font-bold mt-32 mb-10 "}>Our <span
                                className={"service-heading text-blue-800"}>Services</span></h1>
                            <p>Homoeopathic treatment works on the basic principle that is <span
                                className={"services-header-desc "}>"Like Cure Like"</span>. This way of treatment
                                is secure, instant, safe, and sound too</p>
                            <p>Because in homeopathy a patient is treated separately as an individual.This helps the
                                victim to get health back fastly and<span
                                    className={"services-header-desc "}> permanently</span>.In </p>
                            <p>Homeopathy, <span className={"services-header-desc "}>medication </span>is provided
                                by the complete study of each subject separately. This helps to find the main
                                symptoms in every </p>
                            <p>particular case. Which helps to permanently get rid of that disease.<span
                                className={"services-header-desc "}> Homeopathy </span>is an effective system of
                                healing that </p>
                            <p>assists the body’s natural tendency to heal itself.</p></div>
                    </div>
                    <div
                        className="md:p-8 p-2 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-6 h-full text-center justify-center align-center">

                        <div className="box col-sm-6 col-lg-3 bg-white shadow-xl rounded-xl">

                            <img className={"service-img  "} src={service} style={{margin:"auto"}}/>
                            <div className={"img-tag rounded-b-xl"}>
                                <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                                    <h3>NEUROLOGY</h3>
                                </h1>
                                <div className={"text-md hover:text-blue-400 cursor-pointer"}
                   onClick={()=>{navigator("/neuro")}}>Read More</div>

                            </div>

                        </div>
                        <div className="box col-sm-6 col-lg-3 shadow-xl rounded-xl">

                            <img className={"service-img "} src={service1} style={{margin:"auto"}}/>

                            <div className={"img-tag rounded-b-xl"}>
                                <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                                    <h3>LIVER DISEASE</h3>
                                </h1>
                                <div className={"text-md hover:text-blue-400  cursor-pointer"}
                   onClick={()=>{navigator("/liver")}}>Read More</div>
                            </div>



                        </div>

                        <div className="box col-sm-6 col-lg-3 shadow-xl rounded-xl">
                            <img className={"service-img"} src={service2} style={{margin:"auto"}}/>
                            <div className={"img-tag rounded-b-xl"}>
                                <h1 className={"img-tag--title text-xl mt-2 mb-2 cursor-pointer"}>
                                    <h3>GYNECOLOGY</h3>
                                </h1>
                                <div className={"text-md hover:text-blue-400 "}
                   onClick={()=>{navigator("/gyne")}}>Read More</div>
                            </div>

                        </div>

                        <div className="box col-sm-6 col-lg-3 shadow-xl rounded-xl">
                            <img className={"service-img"} src={service3} style={{margin:"auto"}}/>
                            <div className={"img-tag rounded-b-xl"}>
                                <h1 className={"img-tag--title text-xl mt-2 mb-2"}>
                                    <h3>SKIN DISEASE</h3>
                                </h1>
                                <div className={"text-md hover:text-blue-400  cursor-pointer"}
                   onClick={()=>{navigator("/skin")}}>Read More</div>
                            </div>

                        </div>

                    </div>
                    <button
                                onClick={()=>{
                                    navigator("/services")
                                }}
                                className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                                Learn More
                            </button>
                </section>
                {/*--------------------Service Section ends here---------------------------------------->*/}

                {/*----------------------------------TESTIMONIAL----------------------------------->*/}
                <div className={"patient-review--heading "}>
                <div className={"patient-review--heading-container bg-blue-900"}>
                    <h1 className={"text-5xl font-bold mt-32 text-white pb-3 pt-3"}>Our   <span
                        className={"service-heading text-blue-300 whitespace-normal"}>    Patient's</span>  Testimonials</h1>
                </div>
             </div>
                    <div className={"bg-blue-500 pt-8"}>
                        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} ariaLabel={"Testimonial"} showStatus={false}>
                            <div className={""}>
                                <img className="" src={review1}
                                     style={{
                                         width: "90%",
                                         height: "30%",
                                         backgroundPosition: "center center",
                                         backgroundRepeat: "no-repeat",
                                     }}
                                     alt={"review"} />
                                <div className={"bg-white m-4 p-4 text-blue-500 text-xl mb-24 shadow-xl rounded-lg"}>
                                    Hi, I was having warts over my eyelids and it was quite embarrassing. My friends used to make fun of it and I wasn't able to be myself. After visiting Dr. Roy's Clinic and following his medication I lost my warts within 2 months. Today I can play with my friends without thinking what they might say.
                                    <br />  </div>
                            </div>
                            <div className={""}>
                                <img className="" src={review2}
                                     style={{
                                         width: "90%",
                                         height: "30%",
                                         backgroundPosition: "center center",
                                         backgroundRepeat: "no-repeat",
                                     }}
                                     alt={"review"} />
                                <div className={"bg-white m-4 p-4 text-blue-500 text-xl shadow-xl rounded-lg"}>
                                    I was suffering with tremendous amount pimples on my face. It was very difficult for me to have a normal life like others. After visiting Dr. Roy and following his medications I started seeing the results in 10 days. Now I can go out without being conscious about what people might think.
                                    <br />  </div>
                            </div>
                        </Carousel>
                    </div>
                    <div>
                        <Dialog
                            fullWidth={true}
                            fullScreen={true}
                            maxWidth={"md"}
                            open={videoDialogVisible}
                            onClose = {()=>{
                                setVideoDialogVisible(false)}}>
                            <Button
                                color={"error"}
                                startIcon={<Close/>}
                            onClick={()=>{
                                setVideoDialogVisible(false)
                            }}
                            >Close</Button>
                            <YoutubeEmbed embedId={videoUID}/>
                        </Dialog>
                    </div>

                 {/*----------------------------------TESTIMONIAL ENDS----------------------------------->*/}

                 {/*-------------------------------Intro video---------------*/}
                 <div className="video-wrap w-full">
                     <div className="video-tabs" >
                         <div className="video-tabs_tab-panel tabs-pannel--selected" role="tabpannel" id="video-tabs1" aria-labelledby="tabs-0">
                             <div className="video-area">
                             <img className="videoimg" src={videocover} alt={"review"}/>
                                 <div className="d-table">
                                     <div className="d-table-cell">
                                         <div className="container">
                                             <div className="video-item">
                                                <div className="video-content">
                                                    <a onClick={()=>{setVideoDialogVisible(true)
                                                                    setVideoUID("FXPLO6v0cwc")}}>
                                                        <FontAwesomeIcon className="video-play-icon" icon={faPlayCircle} />
                                                     <h3 className="video-head">Watch clinic Introduction</h3>

                                                    </a>
                                                 </div>
                                                 </div>
                                             </div>
                                         </div>
                                 </div>
                             </div>

                         </div>
                     </div>
                     </div>





                       {/*----------------------------------MEET OUR DOCTORS SECTION---------------------------------------------->*/}

                    <div className="team-area ptb-100">

                        <div className="team-container md:w-7/8 w-full">
                            <div className="team-section-title">
                                <h2>Meet Our Doctors</h2>
                            </div>
                            <div className="w-full text-center justify-center align-center">
                                <Swiper
                                    // install Swiper modules
                                    style={{padding:"12px"}}
                                    modules={[ Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                                    spaceBetween={50}
                                    slidesPerView={(isMobileView)?1:3}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    parallax={true}
                                    autoplay={{ delay: 3000 }}
                                >
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3">

                                            <img className={"team-photo team-2 "} src={team2} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Amitava Sinharay</h1>
                                                <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3  ">
                                            <img className={"team-photo team-1 "} src={team} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Shubham Roy</h1>
                                                <h2 className="team-speciality mt-2 text-l ">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="team-box col-sm-6 col-lg-3  ">

                                     <img className={"team-photo team-6 "} src={team6} style={{margin:"auto"}}/>
                                        <div className={"team-photo-about pb-20x"}>
                                    <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Ashish Thakur</h1>
                                             <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                       <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                        </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3  ">

                                            <img className={"team-photo team-4 "} src={team4} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Trina Banerjee</h1>
                                                <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3  ">

                                            <img className={"team-photo team-5 "} src={team5} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4"}>Dr Ajit Gupta </h1>
                                                <h2 className="team-speciality mt-2 text-l">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div className="team-box col-sm-6 col-lg-3  ">

                                     <img className={"team-photo team-3 "} src={team3} style={{margin:"auto"}}/>
                                       <div className={"team-photo-about pb-20x"}>
                                      <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Sukanya Ghosal</h1>
                                         <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                          <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                             </div>
                                              </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3">

                                            <img className={"team-photo team-2 "} src={gulabsha} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Gulabsha Khatun</h1>
                                                <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div></SwiperSlide>
                                    <SwiperSlide>
                                        <div className="team-box col-sm-6 col-lg-3  ">

                                            <img className={"team-photo team-7 "} src={team7} style={{margin:"auto"}}/>
                                            <div className={"team-photo-about pb-20x"}>
                                                <h1 className={"team-photo-about-desc text-2xl mt-4 "}>Dr Debarpita Halder</h1>
                                                <h2 className="team-speciality  mt-2 text-l">General Physician</h2>
                                                <h3 className="team-qualification  ">B.H.M.S(WBHUS)(KOL)</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                       </div>
                       </div>
                       </div>
                    {/*-------------------------------------- Team-Section ends here------------------------------------------------------------>*/}


                 {/*------------------------------------------Video-Testimonials starts here------------------------------------------------------------>*/}

                 <div className={"patient-video-review--heading mb-5"}>
                        <div className={"patient-video-review--heading-container bg-blue-900"}>
                            <h1 className={"text-5xl font-bold mt-32 text-white pb-3 pt-3"}>Let's Hear    <span
                                className={"service-heading text-blue-300 whitespace-normal"}>   Some</span>  Testimonials</h1>
                        </div>
                     <div className="w-full text-center justify-center align-center mt-4">
                         <Swiper
                             // install Swiper modules
                             style={{padding:"12px"}}
                             modules={[ Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                             spaceBetween={50}
                             slidesPerView={(isMobileView)?1:3}
                             onSlideChange={() => console.log('slide change')}
                             onSwiper={(swiper) => console.log(swiper)}
                             parallax={true}
                             autoplay={{ delay: 3000 }}
                         >
                             {
                                 patientsList.map((item)=>(
                                     <SwiperSlide>
                                         <div className={"bg-white rounded-xl shadow-xl p-2 hover:bg-blue-500 cursor-pointer"}
                                              onClick={_=>{
                                                  setVideoUID(item.id)
                                                  setVideoDialogVisible(true)
                                              }}>
                                             <img className={"hover:skew-y-12 hover:opacity-70"} src={item.poster} alt={"patient image"}/>
                                         </div>
                                     </SwiperSlide>
                                 ))
                             }
                         </Swiper>
                     </div>
                     <button
                         className={"mt-4 ml-2  px-12 bg-blue-800 rounded cursor-pointer shadow-lg p-4 m-4 font-bold text-white hover:bg-black  hover:text-white  text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}
                         onClick={()=>{navigator("/patients")}}>View more
                     </button>
                    </div>



                 {/*---------------------------------------------Video-section-ends-here------------------------------------------------*/}
</div>
                )
    }
    


   

