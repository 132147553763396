import React from 'react'
import './styles/Liver.css'
import d1 from '../raw/img/DentalCaries.jpg'
import d2 from'../raw/img/Pyorrhea.jpg'
import d3 from '../raw/img/teethgrinding.jpg'
import d4 from '../raw/img/gingivitis.jpg'
export default function Dental(){
    return(
        <div>
            <div className="neuro-main-container ">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                          DENTAL
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Dental</h4>
                        </div>
                    </div>
                  
                 </div>


                 
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Dental Caries
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Dental Caries</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Dental caries, also referred to as tooth decay or cavities, is one of the most widespread persistent diseases and is also one of the most preventable diseases. <br />When we eat certain foods, the bacteria on our teeth breaks them down and produces acids that can seriously damage the hard tissues of your tooth. 
                 <br/>The result is the formation of dental caries (cavities).</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Dental Caries?</div>
                         <p className='ml-8'>Symptoms of Dental Caries,  includes : <br/><span className='text-blue-900 font-bold'> •	Small Holes<br/>
                              •	Pits in the Affected Tooth<br/>   •	Consistent Toothache</span> </p>
                            <br/>
                               <div className='font-bold text-lg m-auto text-left '>Dental Caries Treatments : </div>
                           <p className='text-xl ml-10 mt-2'>     
                            •	Fillings  <br/>• Crown <br/> • Root Canal <br/>•	Extraction</p>
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Acid flour, Staphysagria, Kreosote, Hekla lava.</p>
                        </div>
                        </div>
                        </div>
                          {/*--------------disease2-----------------------------------------*/}
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Pyorrhoea
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl ml-4  mt-8'>Homeopathy Treatment for Pyorrhea</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Pyorrhoea is a serious gum infection where the ligaments, gums, and supporting bones are damaged. 
                 <br/>There is a pus discharge from the roots of the teeth and gums. This pus, when swallowed along with food, leads to various infections. It is usually the result of poor oral hygiene. Pyorrhea is caused by bacteria known as Pyorrhea alveolaris. It usually occurs in persons over 40 years.</p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Pyorrhoea?</div>
                         <p className='ml-8'>Symptoms of Pyorrhoea,  includes : <br/><span className='text-blue-900 font-bold'> •	Swollen and puffy gums
                                 • Bright red, dusky red, purplish gums or shiny surface<br/>
                                 •  Bleeding gums<br/>
                                 •	Bad breath<br/>
                                 •	Pain during eating<br/>
                                 •	Tenderness of gums<br/>
                                 •	Loose teeth<br/>
                                 •	New spaces will develop between your teeth<br/>
                                 •	Recession of gums<br/>
                                 •	Pus discharge<br/>
                                 •	Pocket formation between tooth and gums<br/>
                                 •	Bone resorption occurs in the advance stages of a disease<br/>
                                 •	Periodontal abscess
                                 <br/></span> </p>
                            <br/>
                               <div className='font-bold text-xl m-auto text-left '>Types Of Pyorrhea : </div>
                           <p className='text-xl ml-10 mt-2'> There are different types of periodontitis. The more common types are:<br/>    
                            •	Chronic Periodontitis	 <br/> •	Aggressive Periodontitis <br/> •	Necrotizing Periodontal Disease </p>
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Merc Sol, Carbo Veg, Phosphorus.</p>
                        </div>
                        </div>

                     
                </div>  

                
                          {/*--------------disease4-----------------------------------------*/}      
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Gingivitis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Gingivitis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Gingivitis is a common and mild form of gum disease that causes irritation, redness and swelling of your gingiva(the part of gum around the base of teeth).  <br />It's important to take gingivitis seriously and treat it promptly. Gingivitis can lead to much more serious gum disease called periodontitis and tooth loss. 
                          The most common cause of gingivitis is poor oral hygiene.</p>

                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Gingivitis ?</div>
                         <p className='ml-8'>Symptoms of Dental Gingivitis,  includes : <br/><span className='text-blue-900 font-bold'>
                         •	Swollen or puffy gums<br/>
                         •	Dusky red or dark red gums<br/>
                         •	Gums that bleed easily when you brush or floss<br/>
                         •	Bad breath<br/>
                         •	Receding gums<br/>
                         •	Tender gums<br/>

                                
                                
                                
                                </span> </p>
                            <br/>
                               <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Gingivitis: </div>
                            <p className='   text-xl font-bold '>Mercurius Sol, Kreosotum, Carbo Vegetabilis, Alumen, Lachesis, Phosphorus, Silicea, Staphysagria, Thuja occidentalis, External applications. </p>
                        </div>
                        </div>
                        </div>            
                
        
        
        
        
                          {/*--------------disease3-----------------------------------------*/}   
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Teeth grinding /Bruxism
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Teeth grinding /Bruxism</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Teeth grinding is a condition whereby one excessively grinds or clenches their teeth. 
                 <br/>Typically grinding and clenching occurs mostly when sleeping, but some individuals may grind and clench during the day as well. Teeth grinding is also called as bruxism.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d3} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Dental Caries?</div>
                         <p className='ml-8'>Symptoms of Teeth grinding ,  includes : <br/>
                         <span className='text-blue-900 font-bold'>
                         •	Teeth that are flattened, fractured, chipped or loose<br/>
                        •	Worn tooth enamel, exposing deeper layers of our tooth<br/>
                           •	Increased tooth pain or sensitivity<br/>
                           •	Tired or tight jaw muscles, or a locked jaw that won't open or close completely<br/>
                               •	Jaw, neck or face pain or soreness<br/>
                            •	Pain that feels like an earache, though it's actually not a problem with our ear<br/>
                                      •	Dull headache starting in the temples<br/>
                                   •	Damage from chewing on the inside of our cheek<br/>
                                          •	Sleep disruption<br/>

                               </span>
                               </p>
                            <br/>
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Teeth grinding  : </div>
                            <p className='   text-xl font-bold '>Cina, Santoninum, Chamomilla, Belladonna, Arsenic Album, Plantago..</p>
                        </div>
                        </div>
                        </div>            
                
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        </div>
          
        
         






        </div>
    )
}