import './App.css';
import React from 'react'
import ReactDOM from 'react-dom';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import { useNavigate } from 'react-router';
import BookAppointment from "./pages/BookAppointment";
import Company_logo from "./raw/logo/logo.svg";
import {Link} from "@material-ui/core";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import About from './pages/About';
import AppointmentDetails from "./pages/AppointmentDetails";

import Services from './pages/Services';
import Gastro from './pages/Gastro';
import Neuro from './pages/Neuro';
import Liver from './pages/Liver';
import Gyne from './pages/Gyne';
import Skin from './pages/Skin';
import Cardio from './pages/Cardio';
import Child from './pages/Child';
import Uro from './pages/Uro';
import Lungs from './pages/Lungs';
import Dental from './pages/Dental';
import Depress from './pages/Depress';
import Infertility from './pages/Infertility';
import Ent from './pages/Ent';
import Thyroid from './pages/Thyroid';
import Genetic from './pages/Genetic';

import Fever from './pages/Fever';
import Eye from './pages/Eye';
import Extremities from './pages/Extremities';
import Hair from './pages/Hair';
import Diabetes from './pages/Diabetes';
import Spine from './pages/Spine';
import Male1 from './pages/Male1';
import Mind from './pages/Mind';


import Clinics from "./pages/Clinics";
import BookingDone from "./pages/BookingDone";
import {Email, Facebook, Instagram, Mail, Phone, YouTube} from "@mui/icons-material";
import Contacts from "./pages/Contacts";
import Patients from "./pages/Patients";


export default function App() {
    const firebaseConfig = {
        apiKey: "AIzaSyB20G1Lap0CCbdxZr3YdVCm2kneitz5meo",
        authDomain: "drhm-fc6f4.firebaseapp.com",
        projectId: "drhm-fc6f4",
        storageBucket: "drhm-fc6f4.appspot.com",
        messagingSenderId: "145387476520",
        appId: "1:145387476520:web:3678a7603d20e339fa071d",
        measurementId: "G-7CXE620S37"
    };

    const navigator = useNavigate()
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

  return (
      <div className="App">
          <div className={"bg-white shadow-md flex flex-row grid grid-flow-row-dense lg:grid-flow-col-dense gap-4 py-3 px-4 items-center"}>
              <div className={"col-span-2 ml-8"} onClick={()=>{navigator("/")}}>
                  <img src={Company_logo} alt={"company_logo"} style={{width:"80%"}}/>
              </div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50"}
                   onClick={()=>{navigator("/")}}>Home</div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50"}
                   onClick={()=>{navigator("/services")}}>Services</div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50"}
                   onClick={()=>{navigator("/clinic")}}>Locate Clinic</div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50"}
                   onClick={()=>{navigator("/patients")}}>Patients</div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50"}
                   onClick={()=>{navigator("/contact")}}>Contact</div>
              <div className={"rounded-md cursor-pointer font-bold p-4 hover:bg-blue-50 "}
                   onClick={()=>{navigator("/about")}}>About</div>
              <div className={"col-span-2  lg:col-span-1"}>
                  <div className={"bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 font-bold text-white hover:bg-blue-600"}
                          onClick={()=>{navigator("/book-appointment")}}
                  >Book Appointment</div>
              </div>
          </div>
          <Routes>
              <Route exact path={"/"} element={<Home/>}/>
              <Route exact path={"/services"} element={<Services/>}/>
              <Route exact path={"/clinic"} element={<Clinics/>}/>
              <Route exact path={"/patients"} element={<Patients/>}/>
              <Route exact path={"/contact"} element={<Contacts/>}/>
              <Route exact path={"/about"} element={<About/>}/>
              <Route exact path={"/book-appointment"} element={<BookAppointment/>}/>
              <Route exact path={"/booking-done/:id"} element={<BookingDone/>}/>
              <Route exact path={"/appointment-details/:id"} element={<AppointmentDetails/>}/>
              <Route exact path={"/gastro"} element={<Gastro/>}/>
              <Route exact path={"/neuro"} element={<Neuro/>}/>
              <Route exact path={"/skin"} element={<Skin/>}/>
              <Route exact path={"/gyne"} element={<Gyne/>}/>
              <Route exact path={"/liver"} element={<Liver/>}/>
              <Route exact path={"/cardio"} element={<Cardio/>}/>
              <Route exact path={"/child"} element={<Child/>}/>
              <Route exact path={"/uro"} element={<Uro/>}/>
              <Route exact path={"/lungs"} element={<Lungs/>}/>
              <Route exact path={"/infertility"} element={<Infertility/>}/>
              <Route exact path={"/dental"} element={<Dental/>}/>
              <Route exact path={"/ent"} element={<Ent/>}/>
              <Route exact path={"/depress"} element={<Depress/>}/>
              <Route exact path={"/hair"} element={<Hair/>}/>
              <Route exact path={"/eye"} element={<Eye/>}/>
              <Route exact path={"/thyroid"} element={<Thyroid/>}/>
              <Route exact path={"/fever"} element={<Fever/>}/>
              <Route exact path={"/extremities"} element={<Extremities/>}/>
              <Route exact path={"/genetic"} element={<Genetic/>}/>
              <Route exact path={"/diabetes"} element={<Diabetes/>}/>
              <Route exact path={"/spine"} element={<Spine/>}/>
              <Route exact path={"/male1"} element={<Male1/>}/>
              <Route exact path={"/mind"} element={<Mind/>}/>


          </Routes>

          <footer className={"bg-blue-800 text-white p-4 mt-32"}>
              <div className={"grid md:grid-cols-4 grid-cols-1 grid-flow-cols"}>
                  <div className={"text-2xl font-bold text-left"}>
                      Dr. Roy's Homoeopathy <br/>
                  </div>
                  <div className={"text-xl text-left mt-8 md:mt-0"}>
                      Contact<br/>
                      <div className={"text-left text-s mt-4"}><a href={"tel:9771818569"} target={"_blank"}><span className={"text-lg"}><Phone/></span> +91 (0) 9771818569</a></div>
                      <div className={"text-left text-s mt-4"}><a href={"tel:8709518040"} target={"_blank"}><span className={"text-lg"}><Phone/></span> +91 (0) 8709518040</a></div>
                      <div className={"text-left text-s mt-4"}><a href={"mailto:drroyshomoeopathy6@gmail.com"} target={"_blank"}><span className={"text-lg"}><Email/></span> drroyshomoeopathy6@gmail.com</a></div>
                  </div>
                  <div className={"text-xl text-left mt-8 md:mt-0"}>
                      Support<br/>
                      <div className={"text-left text-s mt-4"}><a href={"mailto:drroyshomoeopathy6@gmail.com"} target={"_blank"}><Mail/> drroyshomoeopathy6@gmail.com</a></div>
                  </div>
                  <div className={"text-xl text-left mt-8 md:mt-0"}>
                      Social<br/>
                      <div className={"text-left text-s mt-4"}><a href={"https://www.facebook.com/Vivekanand-Homoeo-Clinic-Pharmacy-104031711561361"} target={"_blank"}><Facebook/> Facebook</a></div>
                      <div className={"text-left text-s mt-4"}><a href={"https://www.instagram.com/roy_s_homeopathy_"} target={"_blank"}><Instagram/> Instagram</a></div>
                      <div className={"text-left text-s mt-4"}><a href={"https://www.youtube.com/channel/UCqP2QTRqXVKGka-MWz47VJg"} target={"_blank"}><YouTube/> YouTube</a></div>
                  </div>
              </div>
              <div className={"mt-8"}>
                  <span className={"mt-8 text-xs"}>This website is under copyright protection. Any use of unauthorized information is prohibited and subject to copyright protection law. Any unauthorized access of data will be considered an act of cyber attack on the origination and the other party will be charged under the IT Act of Indian Constitution.</span>
              </div>
          </footer>
      </div>
  );
}

//export default App;
