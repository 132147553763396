import React from 'react'
import './styles/Uro.css'
import d1 from '../raw/img/kidneystone.jpg'
import d2 from '../raw/img/uti.jpg'
import d3 from '../raw/img/uretritis.jpg'
import d4 from '../raw/img/chronic.jpg'
export default function Uro(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                         UROLOGY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Urology</h4>
                        </div>
                    </div>
                  
                 </div>
                 <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Kidney Stone
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Kidney Stone</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p> Kidney stones are clumps developed from solidified crystals in the kidney or urinary tract.<br/>
                  The urine has all the ingredients that form the stone, but all these ideally pass through without our knowledge.<br/>
                  When there is an imbalance in any of these substances, the crystals cluster together into stones. The size of the stone can be as small as a grain of sand to one as large as the size of a golf ball. The most commonly found kidney stone in humans is usually made of calcium oxalate. Kidney stones are one of the most painful urologic disorders. Men tend to be affected more frequently than women.</p>
                 </div>
                 
                           
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Kidney Stone</div>
                         <p className='ml-8'> <br/>
•		Agonizing pain in the lower back just below the ribs(Groin area)<br/>
•	nausea <br/>
•	vomiting<br/>
•	blood in the urine (haematuria)<br/>
•	restlessness <br/>
•	fever (if the infection is present)
<br/>
 </p>
                            <br/>
                              
                            








                
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Sarsaparilla, Nux Vomica, Cantharis, Berberis Vulgaris</p>
                        </div>
                        </div>
                        </div>



                        {/*---------------------------d2---------------------------------*/}

                        <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Urinary Tract Infection
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Urinary Tract Infection</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>Infection of any of the organs of the urinary tract such as the kidneys, Ureter (tube between kidney to the bladder), urinary bladder, and urethra, may broadly be called a UTI. Specifically following terms are used: pyelonephritis or nephritis (infection - inflammation) of the kidney; ureteritis (for ureters), cystitis (urinary bladder), and urethritis (urethra). Pyelonephritis is generally a serious condition</p>
                 </div>
                 
                           
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Causes of Urinary Tract Infection</div>
                         <p className='ml-8 text-md'>Urinary tract infection is most common in women affecting the bladder and urethra. <br/>


                         <span className='text-red-800 mt-6 font-bold'>Infection in the bladder</span> : It is also known as cystitis mainly caused due to E-coli bacteria. It mainly decent from
                          the gastrointestinal tract as the urethra opening is close to the anal opening in females, bacteria travels and
                           causes infection, commonly seen in young sexually active women.<br/><br/>

                           <span className='text-red-800 mt-6 font-bold'>Infection in the urethra</span> :It is also known as urethritis. It is caused mainly due to sexual intercourse, as urethral proximity to the vagina sexually transmitted diseases like STDs, Gonorrhea, and Herpes simplex can also cause urethritis.
                           


<br/>
<br/>
 </p>
                            <br/>
                               
                            
<div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Urinary Tract Infection</div>
                         <p className='ml-8'><span className='text-xl font-bold'>Pulmonary function test:</span> <br/>
                         •	Cloudy or bloody urine, which may have a foul or strong odor.<br/>
•	Low-grade fever (not mandatory that everyone will have a fever).<br/>
•	Constant pain or burning while urination.<br/>
•	Cramping or sensation of pressure in the lower abdomen, occasionally in back<br/>

<br/>
 </p>
                               <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathic Treatment for Urinary Tract Infection : </div>
                            <p className='   text-xl font-bold '>Cantharis, Sarsaparilla, Apis Mel, Berberis Vulgaris, Clematis, Sepia, Staphysagria. </p>
                        </div>
                        </div>
                        </div>
                
              {/*------------------------------------------------------------d3--------------------------------------------*/}

              <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Urethritis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Urethritis</h2>
                 </div>

                 
                
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>Urethritis is a case where a patient gives a history of pain, swelling, redness of the meatus, and scalding during micturition. There is pus or mucopurulent discharge, usually in the morning. Urethritis can be of two types- specific urethritis caused by gonococci infection and non-specific urethritis caused by infections other than gonococci infections</p>
                 </div>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto w-auto h-96 " src={d3} alt={"disease-img"}/>
                     </div>
                 <div>
                            <div className='  text-2xl text-center text-green-600 font-bold '>Homeopathic Treatment of Urethritis : </div>
                            <p className='   text-xl font-bold '>Cannabis sativa, Cantharis vesicatoria, Petroselinum, Mercurius solubilis, Medorrhinum, Thuja occidentalis, Argentums nitricum</p>
                        </div>




                        {/*---------------------------------------------------d4-----------------------------------*/}


                        <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        CHRONIC KIDNEY DISEASE                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Chronic Kidney Disease</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p> Chronic kidney disease also known as chronic renal failure, chronic renal disease, or chronic kidney failure, is much more widespread than people realize.It often goes undetected and undiagnosed until the disease is well advanced.
                 <br/>
                 It is not unusual for people to realize they have chronic kidney failure only when their kidney function is down to 25 percent of normal. </p>
                 
                 </div>
                 
                           
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Chronic Kidney Disease </div>
                    <p className='ml-8'> <br/>
                    •	Anemia<br/>
•	Blood in urine<br/>
•	Dark urine<br/>
•	Decreased mental alertness<br/>
•	Decreased urine output<br/>
•	Edema - swollen feet, hands, and ankles (face if edema is severe)<br/>
•	Fatigue (tiredness)<br/>
•	Hypertension (high blood pressure)<br/>
•	Insomnia<br/>
•	Itchy skin, can become persistent<br/>
•	Loss of appetite<br/>
•	Male inability to get or maintain an erection (erectile dysfunction)<br/>
•	Frequent urination, especially at night<br/>
•	Muscle cramps<br/>
•	Muscle twitches<br/>
•	Nausea<br/>
•	Pain on the side or mid to lower back<br/>
•	Panting (shortness of breath)<br/>
•	Protein in urine<br/>
•	Sudden change in bodyweight<br/>
•	Unexplained headaches<br/>

<br/>
 </p>


 <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>STAGES OF CHRONIC KIDNEY DISEASE</div>
                         <p className='ml-8'>
                         Stage 1 - GFR rate is normal. However, evidence of kidney disease has been detected.<br/>
Stage 2 - GFR rate is lower than 90 milliliters, and evidence of kidney disease has been detected.<br/>
Stage 3 - GFR rate is lower than 60 milliliters, regardless of whether evidence of kidney disease has been detected.<br/>
Stage 4 - GRF rate is lower than 30 milliliters, regardless of whether evidence of kidney disease has been detected.<br/>
Stage 5 - GFR rate is lower than 15 milliliters. Renal failure has occurred.<br/>
<br/>

<br/>
 </p>

 
                               <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathic Treatment for Chronic Kidney Disease: </div>
                            <p className='   text-xl font-bold '>Apis Mellifica, Arsenic Album, Aurum Mur, Belladona, Cantharis, Conavallaria. </p>
                        </div>
                        </div>
                        </div>
                

                    {/*--------------------------------------------------------------------------------------------------*/}


<div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE URO PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CYSTITIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BED WETTING ENURESIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>NEPHRITIC SYNDROME</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>RENAL COLIC</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>PYURIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>HEMATURIA</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>URETHRAL STRICTURE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>PROTEINURIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>DYSURIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>PROSTATE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INCREASED FREQUENCY OF MICTURITION</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INCONTINENCE OF URINE</div>
            </div>
           
              
           
</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>

















                
        </div>
          </div>
    )
}