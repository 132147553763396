import React from 'react'
import './styles/common.css'
import diabetes2 from '../raw/img/diabetes2.jpg'
import gd from '../raw/img/gd.jpg'

import { useNavigate } from 'react-router';

export default function Diabetes(){
    const navigator = useNavigate()
    return(

    <div>
      
      <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                        DIABETES
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Diabetes</h4>
                        </div>
                    </div>
                  
                 </div>
{/*-----------------------------------------------------d-------------------------------------*/}


<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                       Diabetes
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Diabetes</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Diabetes is a disease that occurs when your blood glucose, also called blood sugar, is too high. Over time, having too much glucose in your blood can cause health problems, such as heart disease, nerve damage, eye problems, and kidney disease. You can take steps to prevent diabetes or manage it.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={diabetes2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                    

                     <div className='text-red-600 font-bold text-3xl mt-4 m-auto text-left p-3 '> There are 2 types of Diabetes :</div>
                         <p className='ml-8 text-md '>
                         <span className='text-red-600 mt-0 text-md font-bold'>
                         (1) Type 1 Diabetes :</span>Type 1 diabetes occurs when your immune system, the body’s system for fighting infection, attacks and destroys the insulin-producing beta cells of the pancreas. Scientists think type 1 diabetes is caused by genes and environmental factors, such as viruses, that might trigger the disease. <br/>
                         <span className='text-red-600 mt-0 font-bold'>(2) Type 2 Diabetes : </span> Type 2 diabetes—the most common form of diabetes—is caused by several factors, including lifestyle factors and genes.<br/>

<br/>
<br/></p>




                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Some general symptoms of Diabetes</div>
                         <p className='ml-8'><span className='text-blue-900 text-md font-bold'>
                          • increased thirst and urination fatigue<br/>
                          • increased hunger<br/>
                          • fatigue<br/>
                          • blurred visionn<br/>
                          • numbness or tingling in the feet or hands<br/>
                          • sores that do not heal <br/>
                          • unexplained weight loss <br/></span>
                          <br/><br/><span className='text-red-600 text-lg font-bold'>Symptoms in men :</span> men with diabetes may have a decreased sex drive, erectile dysfunction (ED), and poor muscle strength.<br/>
                          <span className='text-red-600 text-lg font-bold'>Symptoms in women :</span> Women with diabetes can also have symptoms such as urinary tract infections, yeast infections, and dry, itchy skin.<br/><br/>
                          </p>
                            <br/>
</div>
</div>








                                         
                            <div className='d-content bg-green-400 '>
                    <h2 className='text-3xl ml-8 text-black p-3 font-bold mt-8'>Symptoms observed in Type1 & Type2 Diabetes</h2>
                      </div>
                            
                      <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                          <div>
                            <div className='text-red-600 font-bold text-xl    mt-4 '>Symptoms of Type1 Diabetes:</div>
                         <div className='ml-8 mt-0'> <br/><span className='text-blue-900 font-bold'>
                         	•	extreme hunger <br/>
                            •	increased thirst<br/>
                            •   unintentional weight loss<br/>
                            •	frequent urination <br/>
                            •	blurry vision<br/>
                            •   tiredness<br/>
                            </span> </div> </div>
                                
                                 
                                <div>
                                 <div className='text-red-600 font-bold text-xl   mt-4  '>Symptoms of Type2 Diabetes :</div>
                         <div className='ml-8 mt-0'> <br/><span className='text-blue-900 font-bold'>
                         	•	increased hunger <br/>
                            •	increased thirst<br/>
                            •   increased urination<br/>
                            •   blurry vision & tiredness <br/>
                            •	sores that are slow to heal <br/>
                             	</span> </div>
                                 </div></div></div>
                                 
                                 
                                 
                                 <div className='text-red-600 font-bold text-2xl  text-justify ml-6  mt-4 '>Risk factors for Type1 Diabetes:</div>
                                       <p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>• Type 1 diabetes effecty the child or teenager,
                                        if you have a parent or sibling with the condition, or carry certain genes that are linked to the disease.</p>
                                 


                                 <div className='text-red-600 font-bold text-2xl  text-justify ml-6  mt-4 '>Risk factors for Type2 Diabetes:</div>
                                       <p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>•  overweight
                                       •     are age 45 or older<br/>
                                       •     have a parent or sibling with the condition<br/>
                                       •     aren’t physically active<br/>
                                       •     have had gestational diabetes<br/>
                                       •     have prediabetes<br/>
                                       •     have high blood pressure, high cholesterol, or high triglycerides<br/>
                                       •     have African American, Hispanic or Latino American, Alaska Native, Pacific Islander, American Indian, or Asian American ancestry

<br/></p><br/><br/>

<div className='text-red-600 font-bold text-4xl   text-center ml-6 p-3 '>Causes </div>
<div className='text-black font-bold text-xl   text-justify p-3 ml-6 '>Type1 Diabetes : </div>
<p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>Doctors don’t know exactly what causes type 1 diabetes. 
                                               For some reason, the immune system mistakenly attacks and destroys insulin-producing beta cells in the pancreas.<br/>

Genes may play a role in some people. It’s also possible that a virus sets off the immune system attack.

</p>

<div className='text-black font-bold text-xl   text-justify p-3 ml-6 '>Type2 Diabetes : </div>
<p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>Type 2 diabetes stems from a combination of genetics and lifestyle factors.
                                      Being overweight or obese increases risk too. Carrying extra weight, especially in  belly,
                                       makes  cells more resistant to the effects of insulin on  blood sugar.<br/>

                                       This condition runs in families. Family members share genes that make them more likely to get type 2 diabetes and to be overweight.
</p>

                        
                             <div>
                            <div className='  mt-6 text-2xl text-justify ml-6 text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold text-justify ml-6'>Syzygium Jambolanum, Abroma Augusta, Phaseolus, Cephalandra Indica, Phosphorus </p>                   
                       
                          </div>


{/*-------------------------------------------------------------------------------*/}
<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                        Gestational Diabetes
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Gestational diabetess</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> Gestational diabetes, a type of diabetes that develops during pregnancy, is caused by the hormonal changes of pregnancy along with genetic and lifestyle factors.<br/>
                 The condition is often detected during a routine blood sugar test or oral glucose tolerance test that is usually performed between the 24th and 28th weeks of gestation.

                          In rare cases, a woman with gestational diabetes will also experience increased thirst or urination.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                     <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                         <img className=" m-auto " src={gd} alt={"disease-img"}/>
                       </div>
                       <div>
                    <div className='d1-symp m-auto text-center ml-10 '>     
                       <div className='text-red-600 font-bold text-4xl   text-center ml-6 p-3 '>Causes </div>
                        <p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>Gestational diabetes is the result of hormonal changes during pregnancy. The placenta produces hormones that make a pregnant woman’s cells less sensitive to the effects of insulin. This can cause high blood sugar during pregnancy.

                          Women who are overweight when they get pregnant or who gain too much weight during their pregnancy are more likely to get gestational diabetes.
                                  </p></div>
                          <div>
                             <div className='text-red-600 font-bold text-2xl  text-justify ml-6  mt-4 '>
                                         Risk factors for Gestational Diabetes:</div>
                                       <div className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>•  overweight
                                       •     are over age 25 or <br/>
                                       •     had gestational diabetes during a past pregnancy<br/>
                                       •     have given birth to a baby weighing more than 9 pounds<br/>
                                       •     have a family history of type 2 diabetes<br/>
                                       •     have polycystic ovary syndrome (PCOS)<br/>
                                       

                                        <br/></div><br/><div>

                                 <div>
                                      <div className='text-red-600 font-bold text-2xl  text-justify ml-6  mt-4 '>Complications :</div>
                                       <p className=' ml-6 mt-0 text-black text-lg text-justify mr-6'>•  premature birth
                                       •    higher-than-normal weight at birth <br/>
                                       •    increased risk for type 2 diabetes later in life<br/>
                                       •    low blood sugar<br/>
                                       •    jaundice<br/>
                                       •    stillbirth<br/>
                                       <br/></p><br/>
                                 <div>
                              
                                   <div>
                                      <div className='  mt-6 text-2xl text-justify ml-6 text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                      <p className='   text-xl font-bold text-justify ml-6'>Syzygium Jambolanum, Abroma Augusta, Phaseolus, Cephalandra Indica, Phosphorus </p>                   
                                 </div>
                           </div>
                   </div>
                   </div>
                   </div> </div>

</div>
                           
    </div>
    )
    } 







































































































































































































