import React from 'react'
import './styles/common.css'
import alo from '../raw/img/alo.jpg'
import andro from '../raw/img/andro.jpg'
import dandruff from '../raw/img/dandruff.jpg'
import follicle from '../raw/img/follicle.jpg'
import premature from '../raw/img/premature.jpg'
export default function Hair(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                          HAIR PROBLEMS
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Hair problems</h4>
                        </div>
                    </div>
                  
                 </div>
{/*----------------------------------------------------disease1---------------------------------------*/}
<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        DANDRUFF
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Dandruff</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> Dandruff is a scalp condition that causes flakes of skin to appear. 
                 </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={dandruff} alt={"disease-img"}/>
                     </div>
                     
                     
                     <div className='d1-symp m-auto text-center ml-10 '>

                  <div className='text-2xl text-red-600 text-justify font-bold'>Causes of Dandruff </div><br/>
                      <div className='text-justify text-lg mr-6'>    
                     • Poor Hygiene<br/>
                     • A yeastlike fungus (malassezia) that feeds on oils on the scalps of most adults<br/>
                     • Dry Skin<br/>
                     • Irritated, oily skin<br/>
                     • Other skin conditions, such as psoriasis and eczema<br/></div> <br/>

                     <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                        •	Itching, oily skin,<br/>
                        •	.Irritation or redness of the underlying skin. It tends to be chronic (long-lasting) as well as recurring.<br/>
                        •	Excessive and obvious scaling of skin which is visible in the form of white (grey), the flaky substance<br/>
                        •	Hairfall<br/>
                       
                         </span></p><br/><br/>

                         <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Alopecia Areata :</div>
                            <p className='   text-xl font-bold '>Arsenic Album, Thuja, Mzerium, Phosphorus, </p>
                        </div>
                </div></div>
{/*------------------------------------------------disease2--------------------------------------------*/}
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        ALOPECIA AREATA
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Alopecia Areata</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> Alopecia areata, also known as spot baldness, is a condition in which hair is lost from some or all areas of the body. Often, it results in a few bald spots on the scalp, each about the size of a coin. 
                 
                 Psychological stress and illness are possible factors in bringing on alopecia areata in individuals at risk, but in most cases there is no obvious trigger.People are generally otherwise healthy. In a few cases, all the hair on the scalp is lost (alopecia totalis), or all body hair is lost (alopecia universalis), and loss can be permanent. It is distinctive from pattern hair loss, which is common among males.
                 </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={alo} alt={"disease-img"}/>
                     </div>
                     
                     
                     <div className='d1-symp m-auto text-center ml-10 '>


                     <p className='ml-8 '><span className='text-xl text-red-500 font-bold'>Causes of Alopecia Areata </span><br/><br/>Alopecia areata is thought to be a systemic autoimmune disorder in which the body attacks its own anagen hair follicles and suppresses or stops hair growth<br/> Genetic factors may play a role in alopecia areata.<br/>
                           
                     <span className='text-lg text-black font-bold'>People with the following conditions are at higher risk for developing some form of alopecia areata: </span><br/><br/>
                           
                     • Asthma<br/>
                     • Hay fever<br/>
                     • Thyroid disease<br/>
                     • Pernicious anemia, a type of vitamin deficiency<br/> </p><br/>

                     <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                        •	Small bald patches on scalp or other parts of the body<br/>
                        •	More hair loss in cold weather.<br/>
                        •	Hair grows back in one spot and falls out in another.<br/>
                        •	Patches may get larger and grow together into a bald spot.<br/>
                        •	Fingernails and toenails become red, brittle, and pitted.<br/>
                        •   Loss of lots of hair over a short time.
                         <br/></span></p><br/><br/>

                         <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Alopecia Areata :</div>
                            <p className='   text-xl font-bold '>Arsenic Album, Vinca Minor, Flouric Acid, Phosphorus, </p>
                        </div>
                </div></div>

    {/*------------------------------disease3----------------------------------------------*/}




    <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-4xl font-bold'>
                        <h3>
                            
                        ANDROGENETIC ALOPECIA (FEMALE PATTERN BALDNESS)
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Androgenetic Alopecia </h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> 
                 Androgenetic alopecia (AGA), also known in women as female pattern hair loss, is caused 
                 by androgens in genetically susceptible women and men. The thinning begins between ages 
                 12 and 40 years, the inheritance pattern is polygenic, and the incidence is the same as 
                 in men. In susceptible hair follicles, dihydrotestosterone binds to the androgen receptor,
                  and the hormone-receptor complex activates the genes responsible for the gradual transformation
                   of large terminal follicles to miniaturized follicles. Both young women and young men with AGA
                    have higher levels of 5alpha reductase and androgen receptor in frontal hair follicles compared 
                    to occipital follicles.
                 </p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={andro} alt={"disease-img"}/>
                     </div>
                     
                     
                     <div className='d1-symp m-auto text-center ml-10 '>


                     <div className='ml-8 text-red-600 text-2xl text-justify font-bold'>Causes of Androgenetic Alopecia </div><br/>
                     <div className='text-black mr-6 text-lg text-justify'>Genetics appears to be a significant factor in developing female pattern baldness, which means it runs in families. Women can inherit the gene for pattern baldness from either parent.
                     Androgen is a hormone that plays a role in pattern baldness. Tumors of the pituitary gland or ovary, which secrete androgen, may also lead to hair loss.<br/><br/>
                       </div>    
      <div className='text-xl text-black text-justify font-bold mr-6'>Some other causes for baldness in female : </div><br/>
                     <div className='text-lg text-justify mr-6'>      
                     • Autoimmune disease<br/>
                     • Certain medications, such as those that are used to treat cancer,can cause hair loss as a side effect. However, hair usually regrows once a person has stopped taking the medication<br/>
                     • Hair loss can develop after a significant illness, such as a severe infection, high fever, or surgery.<br/>
                     • Traction alopecia<br/> </div><br/>

                    
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Alopecia Areata :</div>
                            <p className='   text-xl font-bold '>Arsenic Album, Vinca Minor, Flouric Acid, Phosphorus, </p>
                        </div>
                </div>





 {/*------------------------------disease4----------------------------------------------*/}




 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-4xl font-bold'>
                        <h3>
                            
                        PREMATURE GRAYING OF HAIR
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Premature Graying of Hair </h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> 
                 Premature graying of hair (PGH), also known as canities, can have negative effects on appearance, self-confidence, self-esteem, and social acceptance of the affected individual. Hair is said to have greyed prematurely if it occurs before the age of 20 years in Europeans, before 25 years in Asians, and before 30 years in Africans.
                 </p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={premature} alt={"disease-img"}/>
                     </div>
                     
                     
                     <div className='d1-symp m-auto text-center ml-10 '>


                  <br/><br/>   <div className='ml-8 text-red-600 text-2xl text-justify font-bold'>Causes of Premature Graying of Hair </div><br/>
                     <div className='text-black mr-6 text-lg text-justify'>Genetics appears to be a significant factor in developing female pattern baldness, which means it runs in families. Women can inherit the gene for pattern baldness from either parent.
                     Cause of greying is incompletely understood. It is a complex multi-factorial process mainly considered to be an interplay of nutritional, genetic and environmental factors.

                      Stress causing over-activation of the sympathetic nervous system increases noradrenaline release in hair follicles. This overproduction causes depletion of the melanocyte stem cells which are required to produce melanin, the pigment responsible for hair color.<br/><br/>
                       </div>    
      <div className='text-xl text-black text-justify font-bold mr-6'>Some other causes for baldness in female : </div><br/>
                     <div className='text-lg text-justify mr-6'>      
                     • Vitamin deficiencies<br/>
                     • Genetics<br/>
                     • Oxidative stress<br/>
                     • Some medical conitidon like autoimmune diseases,<br/>
                     • Smoking<br/>
                     • White hair is also common in alopecia areata<br/>
                     • Chemical hair dyes and hair products<br/>
                     </div><br/>

                    
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Alopecia Areata :</div>
                            <p className='   text-xl font-bold '>Phosphoric Acid , Lycopodium, Natrum Mur , Vinca Minor, Hairlax
 </p>
                        </div>
                </div>




 {/*------------------------------disease5----------------------------------------------*/}




 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-4xl font-bold'>
                        <h3>
                            
                        SCALP FOLLICILITIS
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Scalp Follicilitis  </h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> 
                 Scalp Folliculitis is a common skin condition in which hair follicles become inflamed. It is usually caused by a bacterial or fungal infection.In early stage it look like small red bumps or white-headed pimples around hair follicles the tiny pockets from which each hair grows. The infection can spread and turn into nonhealing, crusty sores.
                 </p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={follicle} alt={"disease-img"}/>
                     </div>
                     
                     
                     <div className='d1-symp m-auto text-center ml-10 '>


                  <br/><br/>   <div className='ml-8 text-red-600 text-2xl text-justify font-bold'>What are the Symptoms ? </div><br/>
                     
    
                     <div className='text-lg text-justify mr-6'>      
                     • Clusters of small red bumps or white-headed pimples that develop around hair follicles<br/>
                     • A large swollen bump or mass<br/>
                     • Painful, tender skin<br/>
                     • Itchy, burning skin<br/>
                     • Pus-filled blisters that break open and crust over<br/>
                    
                     </div><br/>

                    
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Scalp Follicilitis:</div>
                            <p className='   text-xl font-bold '>  Sulphur, Hepar Sulph, Graphites,  Calcarea Sulph , Mezerium, Hairlax

 </p>
                        </div>
                </div>





 































































 




















































































































































                 </div>
                 </div>
                 )
    }
