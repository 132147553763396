import React from 'react'
import './styles/common.css'
import { useNavigate } from 'react-router';




export default function Mind(){
    const navigator = useNavigate()
    return(
  <div>



  <div className="neuro-main-container">
            
            
            <div className='neuro-banner about-banner container-bn p-0'>
                <div className='neuro-banner-content'>
                    <h3>
                        
                     MIND PROBLEMS
                    </h3>
                </div>
                <div className='banner-ls container-fluid mt-0'>
                    <div className='container'>
                        <ol className='cont-list'>
                            <li className='font-bold text-white '>
                                <a href='./src/pages/Home.js'>
                                    Home
                                </a>
                            </li>
                        </ol>
                        <h4 className='text-white font-bold '>Mind problems</h4>
                    </div>
                </div>
              
             </div>
{/*------------------------------------disease list--------------------------*/}


<div className='  d-banner container-bn-d  p-0 mt-8'>
                <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                    <h3>
                        
                  Mind Problems
                    </h3>
                </div>
             </div>



<div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MIND PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INSOMNIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>ANXIETY</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MANIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>STRESS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CLAUSTRO PHOBIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BIPOLAR DISORDER</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>BRAIN ABSCESS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>HALLUCINATION</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>OBSTRUCTIVE SLEEP APNEA</div>
            </div>

           
              
           
</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>

</div>

</div>


)}