import React from 'react'
import './styles/Child.css'
import d1 from '../raw/img/Meningitis.jpg'
import headache from'../raw/img/headache.jpg'
import d2 from "../raw/img/mumps.png";
import d3 from "../raw/img/pinworm.jpg";
import d4 from "../raw/img/diarrohea.jpg";
import d5 from "../raw/img/endo.jpg";
import autism_1 from '../raw/img/autism_1.jpg'
export default function Child(){
    return(
        <div>
            <div className="neuro-main-container">


                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                            CHILD HEALTH
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Child Health</h4>
                        </div>
                    </div>

                </div>
                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            Autism
                        </h3>
                    </div>
                </div>

                <div className='d-content bg-blue-300 '>
                    <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Autism</h2>
                </div>
                <div className='d-descp text-lg mr-10 mt-8'>
                    <p>Autism is a complex neurological disorder that is detected in children in their initial three years of life, which is the result of the impaired functioning of the brain, expressing itself in the form of the inability of social and communication skills, impaired intellectual faculty, and hence affecting the developmental of the child. The exact cause of autism is not yet fully understood.</p>
                </div>
                <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                    <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                        <img className=" m-auto " src={autism_1} alt={"disease-img"}/>
                    </div>
                    <div className='d1-symp m-auto text-center ml-10 '>
                        <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the symptoms?</div>
                        <p className='ml-8 text-xl font-bold'>Symptoms: <br/><br/>
                            <span className='text-blue-900 mt-0 font-bold'>
                                •	Marked deficits in communication and social interaction<br/>
•	Unable to follow command<br/>
•	Loneliness<br/>
•	Abnormal behavior, such as repetitive acts and excessive possessiveness to certain objects such as cars, dolls, keys<br/>
•	Intellectual impairment<br/>
•	No/Less eye contact<br/>
•	Irritability<br/>
•	Repetition of word<br/>
•	weak Memory<br/>
•	Peevish<br/><br/>
The common end symptoms could be some or many of the following:<br/>
•	Annoying obstinacy in behavior<br/>
•	The poor verbal expression uses gestures to point out his needs<br/>
•	Repeating words or phrases<br/>
•	Laughing, crying without apparent cause<br/>
•	Prefers solitude<br/>
•	Throws Tantrums<br/>
•	Difficulty in social interaction with his age or elders<br/>
•	No eye contact while communication<br/>
•	Unresponsive teaching<br/>
•	Failure to perceive fears of danger leads to jumping from high places, playing with pointed objects<br/>
•	Physical hyperactivity or under-activity<br/>
•	Underdeveloped fine motor skills<br/>
•	Not responsive to verbal instructions with normal hearing<br/>
•	Spinning objects<br/>
•	Inappropriate possessiveness of objects<br/>
•	Over-sensitivity or under-sensitivity to pain<br/>
•	Screaming and shouting out of proportion<br/>
•	Repeats the same words (echolalia)<br/>

                               </span>
                        </p>
                        <br/>
                        <p className='ml-8 text-xl text-red-500 font-bold'> <br/>
                            Causes of Autism :<p/>
                            <span className='text-blue-900 font-bold'>
                                 <ul>
                                    <li>Genetic predisposition</li>
                                    <li>Exposure to environmental chemicals</li>
                                    <li>Intake of some drugs, medicines during pregnancy</li>
                                    <li>Metabolic imbalance</li>
                                    <li>Vaccinations</li>
                                    <li>Stress during pregnancy</li>
                                </ul>
                            </span>
                        </p>
                        <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>
                                <ul>
                                    <li>BAYTA CARB, HYOSCYAMUS, SILICEA, TARANTULA,</li>
                                    <li>RL-43: For IQ Memory Concentration Drops</li>
                                    <li>CEROLIN: Effective for Brain</li>
                                    <li>NEUROPLUS: All Types of Neuro Problem</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>

                {/*----------------disease-2-------------------------------------------------*/}



                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                            Mumps
                        </h3>
                    </div>
                </div>

                <div className='d-content bg-blue-300 '>
                    <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Mumps</h2>
                </div>
                <div className='d-descp text-lg mr-10 mt-8'>
                    <p>Mumps is an infection caused by a virus that's easily spread through saliva and mucus. It usually happens in kids who haven't been immunized. Mumps can affect any part of the body, but it mostly affects saliva-making glands below and in front of the ears (called parotid glands). Those glands can swell if infected.</p>
                </div>
                <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                    <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                        <img className=" m-auto " src={d2} alt={"disease-img"}/>
                    </div>
                    <div className='d1-symp m-auto text-center ml-10 '>
                        <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                        <p className='ml-8 text-red-500 font-bold'> <br/>
                            Symptoms begin after 2 weeks of infection:
                            <p/>
                            <span className='text-blue-900 font-bold'>
                                The classic signs are:<br/>
                                •	pain and swelling in your face and jaw.<br/><br/>
                                Other symptoms may be noticed these are:<br/>
                                •	Fatigue<br/>
                                •	Fever<br/>
                                •	Headache<br/>
                                •	Loss of appetite<br/>
                                •	Muscle aches<br/>
                                •	Weakness<br/>
                            </span>
                        </p>






                        <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>BELLADONA, Merc Sol, Lachesis, THROAT DROP</p>
                        </div>
                    </div>
                </div>

                {/*----------------disease-3-------------------------------------------------*/}





                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            Pinworms
                        </h3>
                    </div>
                </div>

                <div className='d-content bg-blue-300 '>
                    <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Pinworms</h2>
                </div>
                <div className='d-descp text-lg mr-10 mt-8'>
                    <p>A pinworm infection is one of the most common types of human intestinal worm infections. Pinworms are tiny, narrow worms. They're white and less than a half-inch long. Pinworm infections are also known as enterobiasis or oxyuriasis.</p>



                </div>
                <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                    <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                        <img className=" m-auto " src={d3} alt={"disease-img"}/>
                    </div>
                    <div className='d1-symp m-auto text-center ml-10 '>
                        <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Pinworm</div>
                        <p className='ml-8'>
                            •	Itching of the anal or vaginal area<br/>
                            •	Insomnia, irritability, teeth grinding, and restlessness<br/>
                            •	Occasional stomach pain and nausea<br/>
                        </p>
                        <br/>


                        <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment  </div>
                            <p className='   text-xl font-bold '> CINA, SANTONINE, ABROTANUM, CALADIUM </p>
                        </div>
                    </div>
                </div>


                {/*--------------------------------------d4-----------------------------*/}
                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            INFANTILE DIARRHOEA
                        </h3>
                    </div>
                </div>

                <div className='d-content bg-blue-300 '>
                    <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for INFANTILE DIARRHOEA</h2>
                </div>
                <div className='d-descp text-lg mr-10 mt-8'>
                    <p> Child, developing sudden, at first yellow and offensive stool, then greenish and slimy stool, often mixed with undigested food. The condition is ACUTE CATARRHAL INFANTILE DIARRHEA.
                    </p>
                </div>
                <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                    <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                        <img className=" m-auto " src={d4} alt={"disease-img"}/>
                    </div>
                    <div className='d1-symp m-auto text-center ml-10 '>
                        <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                        <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                             1. Fever of 102 degrees or higher<br/>
                             2. Belly pain<br/>
                             3. Blood or pus in his poop or poop tht'Skin black, white or red<br/>
                             4. Sluggishness<br/>
                             5. Vomiting<br/>
                             6. Dry mouth<br/>
                             7. Acting fussy or irritable<br/>
                             8. Unusual drowsiness or sluggishness<br/>
                         </span>
                        </p>
                        <br/>
                        <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>
                                ACONITE NAPELLUS, BRYONIA, VERATRUM ALBUM, GAMBOGIA
                            </p>
                        </div>
                    </div>
                </div>
{/*----------------------------------------------------------------------------------------*/}

                <div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE CHILD  PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>OCD</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BRUXISM</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>DIAPER RASH</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>BED-WETTING(NOCTURNAL ENURESIS) </div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>TEETHING TROUBLE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INFANTILE JAUNDICE</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>INFANTILE PNEUMONIA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INFANTILE DIARRHOEA</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CRADLE CAP</div>
            </div>

           
              
           
</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>








            </div>
        </div>
    )
}
