import React from 'react'
import './styles/Ent.css'
import d1 from '../raw/img/vocal.jpg'
import d2 from '../raw/img/ear.jpg'
import d3 from '../raw/img/tonsil.jpg'
import d4 from '../raw/img/ansomia.jpg'
import d5 from '../raw/img/nosebleed.jpg'


import headache from'../raw/img/headache.jpg'
export default function Ent(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                          ENT(Ear-Nose-Throat)
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>ENT</h4>
                        </div>
                    </div>
                  
                 </div>
                 <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                        Vocal nodules
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for  Vocal nodules</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>A polyp is a growth (usually benign) protruding from a mucous membrane.<br />When polyps are seen over vocal cords they are called vocal polyps or vocal nodules.</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Vocal nodules ?</div>
                         <p className='ml-8'>Symptoms of Vocal nodules: <br/><span className='text-blue-900 font-bold'> •	Hoarseness<br/>
                              •	The voice may also sound low-pitched or slightly breathy.</span> </p>
                            <br/>
                              
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Spongia Tosta, Capsicum, Argentum Nitricum, Calcarea Carbonica, Thuja Occidentalis

.</p>
                        </div>
                        </div>
                        </div>
                          {/*--------------disease2-----------------------------------------*/}
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Tinnitus
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl ml-4  mt-8'>Homeopathy Treatment for Tinnitus</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Tinnitus is an annoying condition where one hears abnormal sounds without actually it being there.  
                 <br/>sounds, such as ringing, buzzing, high-pitched whining, hissing, shrieking, humming, whistling sounds, etc.</p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the causes of Tinnitus?</div>
                         <p className='ml-8'><span className='text-blue-900 font-bold'> •	•	Ear infections<br/>
                                 •	Wax in the ear<br/>
                                
                                 •	Nasal allergies<br/>
                                 •	Drugs (Certain antibiotics, pain killers, methotrexate, quinine, aspirin)<br/>
                                 •	Noise-induced hearing loss<br/>
                                 •	Prolonged exposure to loud noise (industrial )<br/>
                                 •	Ear or head injury<br/>
                                 •  Emotional disorders such as anxiety, depression<br/>
                                
                                 </span> </p>
                            <br/>
                              
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Calcarea carbonica, Carbo vegetabilis, China (Cinchona officinalis), Cimicifuga

</p>
                        </div>
                        </div>

                     
                </div>  

                
                          {/*--------------disease4-----------------------------------------*/}      
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Tonsillitis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Tonsillitis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Tonsillitis is an inflammation of the Tonsils, which results in a sore throat. 
                 Tonsillitis can be caused by either viruses or bacteria. Most cases of tonsillitis go away without antibiotic medication.</p>
                 </div>

                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d3} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Tonsillitis ?</div>
                         <p className='ml-8'>Symptoms of Tonsillitis : <br/><span className='text-blue-900 font-bold'>
                         •	Sore throat<br/>
•	Difficulty feeding (in babies)<br/>

•	Pain with swallowing<br/>
•	Fever<br/>
•	Headache<br/>
•	Abdominal pain<br/>
•	Nausea and vomiting<br/>
•	Cough<br/>
•	Hoarseness<br/>
•	Runny nose<br/>
•	Redness of the tonsils and throat<br/>
•	Tenderness in the glands of the neck (swollen lymph glands)<br/>
•	White patches on the tonsils<br/>
•	Redness of the eyes<br/>
•	Rash<br/>
•	Ear pain (nerves that go to the back of the throat also go to the ear)<br/>

                                
                                
                                
                                </span> </p>
                            <br/>
                               <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Tonsilitis: </div>
                            <p className='   text-xl font-bold '>Belladonna, Kali Muriaticum, Baryta Carb, Phytolacca. </p>
                        </div>
                        </div>
                        </div>            
                
        
        
        
        
                          {/*--------------disease3-----------------------------------------*/}   
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Anosmia
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Anosmia</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Anosmia is the partial or complete loss of the sense of smell. This loss may be temporary or permanent. Common conditions that irritate the nose's lining, such as allergies or a cold, can lead to temporary anosmia.
                       <br/>More serious conditions that affect the brain or nerves, such as brain tumors or head trauma, can cause permanent loss of smell. Old age sometimes causes anosmia.
                        </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are Causes of Anosmia ?</div>
                         <p className='ml-8 text-xl font-bold'>Causes: <br/><br/> 
                         <span className='text-red-500'>Irritation to the mucus membranes lining the nose :</span> <p/> <br/>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	sinus infections<br/>
•	common colds<br/>
•	smoking<br/>
•	the flu, or influenza<br/>
•	allergies (allergic rhinitis)<br/>
•	chronic congestion not related to allergies (nonallergic rhinitis)<br/>

                               </span>
                               </p>
                            <br/>
                            <p className='ml-8 text-xl text-red-500 font-bold'> <br/> 
                            Blockage of the nasal passages :<p/> <br/>
                         <span className='text-blue-900 font-bold'>
                         •	tumors<br/>
•	nasal polyps<br/>
•	bone deformities inside the nose or a nasal septum<br/>


                               </span>
                               </p>


                               <p className='ml-8 text-xl text-red-500 font-bold'> <br/> 
                               Brain or nerve damage:<p/> <br/>
                         <span className='text-blue-900 font-bold'>
                         •	old age<br/>
•	Alzheimer's disease<br/>
•	brain tumors<br/>
•	Huntington's disease<br/>
•	hormonal problems<br/>
•	underactive thyroid<br/>
•	medications, including some antibiotics and high blood pressure medications<br/>
•	multiple sclerosis<br/>
•	Parkinson's disease<br/>
•	schizophrenia<br/>
•	epilepsy<br/>
•	diabetes<br/>
•	exposure to chemicals that burn the inside nose<br/>
•	brain or head injury<br/>
•	brain surgery<br/>
•	malnutrition and vitamin deficiencies<br/>
•	radiation therapy<br/>
•	long-term alcoholism<br/>
•	stroke<br/>


                               </span>
                               </p>
                               
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Alumina, Natrum Mur, Pulsatilla.</p>
                        </div>
                        </div>
                        </div>    

                {/*----------------disease-5-------------------------------------------------*/}   
                  

                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Epistaxis / Nose Bleed
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for  Epistaxis/nose bleed</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>A hemorrhage from the nose is called epistaxis. commonly called nose bleed, which is the loss of blood from the tissue that lines the inside of the nose.</p></div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d5} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>TYPES OF EPISTAXIS:</div>
                         <p className='ml-8'><span className='text-blue-900 font-bold'> •	Anterior Epistaxis </span> -It starts in front of the nose on the lower part of the wall that separates the two sides of the nose (called the septum). Capillaries and small blood vessels in this front area of the nose are fragile and can easily break and bleed. This is the most common type of nosebleed and is usually not serious. These nosebleeds are more common in children and are usually able to be treated at home. <br/>
                         <span className='text-blue-900 font-bold'>  •Posterior nosebleed  </span>- It occurs deep inside the nose. This nosebleed is caused by a bleed in larger blood vessels in the back part of the nose near the throat. This can be a more serious nosebleed than an anterior nosebleed. It can result in heavy bleeding, which may flow down the back of the throat. One may need medical attention right away for this type of nosebleed. This type of nosebleed is more common in adults. </p>
                            <br/>
                              
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Arnica, Ammon Carb, Phosphorus, Arum triphyllum, Ipecac.</p>
                        </div>
                        </div>
                        </div>     
                
        
        
        
        
        {/*------------------------------------------------------------------------------------------*/}
        



<div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE ENT PROBLEMS WE TREAT</h2>
             </div>

             <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
     <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>NASAL POLYP</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>EAR WAX</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>SORE THROAT</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>GOITER</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>OTTORRHEA / EAR DISCHARGE</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>DYSPHAGIA / DIFFICULTY IN SWALLOWING</div>
           </div>
           <div className="box bg-blue-900 ">
          <div className='text-xl font-bold text-white p-3 text-center'>DIPTHERIA</div>
           </div>
           <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
          <div className='text-xl font-bold p-3 text-white text-center'>LARYNGITIS</div>
           </div>
          

          
             
          
</div>
           <button
                           onClick={()=>{
                               navigator("/book-appointment")
                           }}
                           className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                        Consult Now
                       </button>

        
        
        
        
        
        
        
        
        
        
        
        
        </div>
          
        
         






        </div>
    )
}