import styled from "styled-components";

export default styled.div`
  


  display: flex;
 
  
 
  background-color: transparent;
  color: #fff;
  
 

  
`;
