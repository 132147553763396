import React from "react"
import {Email, Facebook, Instagram, Phone, YouTube} from "@mui/icons-material";

export default function Contacts(){
    return(
        <div className={"p-4"}>
            <div className={"bg-gray-100 p-4 m-4 rounded-xl shadow-2xl m-auto"}>
                <div className={"text-5xl font-bold mb-8 mt-8"}>
                    <span className={"text-blue-700"}>Contact</span> Us
                </div>
                <div className={"grid md:grid-cols-3 grid-cols-1 md:grid-flow-cols grid-flow-row gap-4"}>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg"}>
                        <div className={"text-blue-700 font-bold"}>Formal reports</div>
                        <div className={"text-lg mt-4 mb-4"}>In-case of any formal report or communication with our management and medical team, email your queries with related documents here.</div>
                        <div className={"bg-indigo-400 hover:bg-indigo-600 p-2 shadow-lg rounded-lg text-white"}>
                            <a href={"mailto:drroyshomoeopathy6@gmail.com"} target={"_blank"}><span className={"text-md"}><Email/>drroyshomoeopathy6@gmail.com</span></a>
                        </div>
                    </div>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg"}>
                        <div className={"text-blue-700 font-bold"}>Consultancy</div>
                        <div className={"text-lg mt-4 mb-4"}>Get in touch with the top homoeopathy doctors and get your queries clarified! We will connect you with top specialist according to your needs within 24 hours.</div>
                        <div className={"bg-indigo-400 hover:bg-indigo-600 p-2 shadow-lg rounded-lg text-white"}>
                            <a href={"tel:9771818569"} target={"_blank"}><span className={"text-md"}><Phone/> +91(0)9771818569</span></a>
                        </div>
                    </div>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg"}>
                        <div className={"text-blue-700 font-bold"}>Desk</div>
                        <div className={"text-lg mt-4 mb-4"}>Reach us on our help/consultancy desk here for any information related to appointment or schedule. (This is not a 24/7 service, it is subjective to working hours.)</div>
                        <div className={"bg-indigo-400 hover:bg-indigo-600 p-2 shadow-lg rounded-lg text-white"}>
                            <a href={"tel:8709518040"} target={"_blank"}><span className={"text-md"}><Phone/> +91(0)8709518040</span></a>
                        </div>
                    </div>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg text-blue-600 md:mt-8"}>
                        <a href={"https://www.facebook.com/Vivekanand-Homoeo-Clinic-Pharmacy-104031711561361"} target={"_blank"}><span className={"text-md"}><Facebook/> Facebook</span></a>
                    </div>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg text-pink-600 md:mt-8"}>
                        <a href={"https://www.instagram.com/roy_s_homeopathy_"} target={"_blank"}><span className={"text-md"}><Instagram/> Instagram</span></a>
                    </div>
                    <div className={"text-2xl bg-white p-4 rounded-xl shadow-lg text-red-600 md:mt-8"}>
                        <a href={"https://www.youtube.com/channel/UCqP2QTRqXVKGka-MWz47VJg"} target={"_blank"}><span className={"text-md"}><YouTube/> YouTube</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
