
import React, {useEffect} from 'react'

import service from "../raw/img/neurology.jpg"
import service1 from "../raw/img/liver.jpg"
import service2 from "../raw/img/gynecologyjpg.jpg"
import service3 from "../raw/img/skin.jpg"
import service4 from "../raw/img/gastro.jpg"
import service8 from "../raw/img/child.jpg"
import cardio from "../raw/img/heart.jpg"
import lung from "../raw/img/lung.jpg"
import infertility from "../raw/img/infertility.jpg"
import dental from "../raw/img/dental.jpg"
import depression from "../raw/img/depression.jpg"
import uro from "../raw/img/uro.jpg"
import hp from "../raw/img/hairp.jpg"
import fever from "../raw/img/fever.jpg"
import md from "../raw/img/thyroid.jpg"
import od from "../raw/img/diabetes.jpg"
import ep from "../raw/img/eye.jpg"
import sd from "../raw/img/spine.jpg"
import dna from "../raw/img/dna.jpg"
import ent from "../raw/img/ent.png"
import ext from "../raw/img/ext.jpg"
import male from "../raw/img/male.jpg"
import PCOS from "../raw/img/PCOS.jpg"
import mind from "../raw/img/mind.jpg"





import { useNavigate } from 'react-router';

export default function Services(){
    const navigator = useNavigate()

    useEffect(()=>{
        return()=>{window.scrollTo(0, 0)}
    },[])

    return(
        <div><section className={"services "}>
        <div className={"services-header-container"}>
            <div className={"services-header"}>
                <h1 className={"services-header-head text-5xl font-bold mt-32 mb-10 "}>Our <span
                    className={"service-heading text-blue-800"}>Services</span></h1>
                <p>Homoeopathic treatment works on the basic principle that is <span
                    className={"services-header-desc "}>"Like Cure Like"</span>. This way of treatment
                    is secure, instant, safe, and sound too</p>
                <p>Because in homeopathy a patient is treated separately as an individual.This helps the
                    victim to get health back fastly and<span
                        className={"services-header-desc "}> permanently</span>.In </p>
                <p>Homeopathy, <span className={"services-header-desc "}>medication </span>is provided
                    by the complete study of each subject separately. This helps to find the main
                    symptoms in every </p>
                <p>particular case. Which helps to permanently get rid of that disease.<span
                    className={"services-header-desc "}> Homeopathy </span>is an effective system of
                    healing that </p>
                <p>assists the body’s natural tendency to heal itself.</p></div>
        </div>
        <div
            className="row  p-8 flex flex-row grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-6 h-full text-center justify-center align-center">

            <div className={"box col-sm-6 col-lg-3  "} onClick={()=>{navigator("/neuro")}}>

                <img className={"service-img  "} src={service} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>NEUROLOGY</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400 cursor-pointer"}
       onClick={()=>{navigator("/neuro")}}>Read More</div>

                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/liver")}}>

                <img className={"service-img "} src={service1} style={{margin:"auto"}}/>

                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>LIVER DISEASE</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/liver")}}>Read More</div>
                </div>



            </div>

            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/gyne")}}>
                <img className={"service-img"} src={service2} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>GYNECOLOGY</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400 cursor-pointer "}
       onClick={()=>{navigator("/gyne")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "} onClick={()=>{navigator("/book-appointment")}}>
                <img className={"service-img"} src={PCOS} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>POLY CYSTIC OVARIAN DISEASE (PCOD/PCOS)</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400 cursor-pointer "}
       onClick={()=>{navigator("/book-appointment")}}>Consult Now</div>
                </div>

            </div>

            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/skin")}}>
                <img className={"service-img"} src={service3} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2"}>
                        <h3>SKIN DISEASE</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/skin")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/gastro")}}>
                <img className={"service-img"} src={service4} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2"}>
                        <h3>GASTROENTEROLOGY</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/gastro")}}>Read More</div>
                </div>


            </div>

            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/uro")}}>
                <img className={"service-img"} src={uro} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>UROLOGY</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/uro")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/cardio")}}>
                <img className={"service-img"} src={cardio} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>CARDIOVASCULAR DISEASE</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/cardio")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/child")}}>
                <img className={"service-img img-8 "} src={service8} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>CHILD HEALTH</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/child")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/lungs")}}>
                <img className={"service-img img-8 "} src={lung} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>RESPIRATORY DISEASE</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/lungs")}}>Read More</div>
                </div>

            </div>
            <div className={"box col-sm-6 col-lg-3  "}  onClick={()=>{navigator("/ent")}}>
                <img className={"service-img img-8 "} src={ent} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>ENT(Ear-Nose-Throat)</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/ent")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/depress")}}>
                <img className={"service-img img-8 "} src={depression} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>DEPRESSION</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/depress")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/infertility")}}>
                <img className={"service-img img-8 "} src={infertility} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>INFERTILITY</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/infertility")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/dental")}}>
                <img className={"service-img img-8 "} src={dental} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>DENTAL</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/dental")}}>Read More</div>
                </div>

            </div>

            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/hair")}}>
                <img className={"service-img img-8 "} src={hp} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Hair Problems</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/hair")}}>Read More</div>
                </div>

            </div>


            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/eye")}}>
                <img className={"service-img img-8 "} src={ep} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Eye Problems</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/eye")}}>Read More</div>
                </div>

            </div>

            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/thyroid")}}>
                <img className={"service-img img-8 "} src={md} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Thyroid</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/thyroid")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/diabetes")}}>
                <img className={"service-img img-8 "} src={od} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Diabetes</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/diabetes")}}>Read More</div>
                </div>

            </div>

            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/fever")}}>
                <img className={"service-img img-8 "} src={fever} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Fever</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/fever")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/spine")}}>
                <img className={"service-img img-8 "} src={sd} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Spine Disease</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/spine")}}>Read More</div>
                </div>

            </div>


            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/extremitiest")}}>
                <img className={"service-img img-8 "} src={ext} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Extremities</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/extremities")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/male1")}}>
                <img className={"service-img img-8 "} src={male} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Male Diseases</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/male1")}}>Read More</div>
                </div>

            </div>
            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/genetic")}}>
                <img className={"service-img img-8 "} src={dna} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Genetic Disorders</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/genetic")}}>Read More</div>
                </div>

            </div>

            <div className="box col-sm-6 col-lg-3  " onClick={()=>{navigator("/mind")}}>
                <img className={"service-img img-8 "} src={mind} style={{margin:"auto"}}/>
                <div className={"img-tag"}>
                    <h1 className={"img-tag--title text-xl mt-2 mb-2 "}>
                        <h3>Mind Problems</h3>
                    </h1>
                    <div className={"text-md hover:text-blue-400  cursor-pointer"}
       onClick={()=>{navigator("/mind")}}>Read More</div>
                </div>

            </div>




        </div>

    </section></div>
    )
}
