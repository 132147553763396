import React, {useEffect, useState} from "react"
import {Button, InputAdornment, TextField} from "@mui/material";
import {Search} from "@mui/icons-material";
import {fetchClinicList} from "../firebase/fetch/Clinics";

export default function Clinics(){

    const [clinicList, setClinicList] = useState([])
    const [tableClinic, setTableClinic] = useState([])
    const [searchKey, setSearchKey] = useState("")

    useEffect(()=>{
        fetchClinicList()
            .then((data)=>{
                setClinicList(data)
            }).catch(err=>{
                alert(err.message)
        })
        return ()=>{}
    }, [])

    useEffect(()=>{
        setTableClinic(clinicList)
    },[clinicList])

    useEffect(()=>{
        const filterList = []
        if(searchKey !== ""){
            clinicList.forEach(data=>{
                if(data.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.city.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.address.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.state.toLowerCase().includes(searchKey.toLowerCase())){
                    filterList.push(data)
                }
            })
            setTableClinic(filterList)
        }else{
            setTableClinic(clinicList)
        }

    },[searchKey])


    return (
        <div className={"bg-white"}>
            <div className={"bg-white shadow-xl p-4 text-left"}>
                <div className={"text-3xl font-bold ml-8 mt-8"}>Looking for a <span className={"text-blue-500"}>clinic</span>?</div>
                <div className={"m-8 mb-6"}>
                    <TextField
                        label="Search"
                        fullWidth
                        type="text"
                        style={{fontSize:"1.2em"}}
                        onChange={(e)=>{setSearchKey(e.target.value)}}
                        value={searchKey}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                </div>
            </div>
            <div className={"bg-white rounded-xl shadow-xl mt-4 mb-8 text-left"}>
                <div className={"grid md:grid-cols-2 grid-cols-1 grid-flow-row w-full h-full"}>
                    {
                        tableClinic.map(data=>(
                          <div className={"bg-white rounded-xl shadow-xl p-4 m-4"}>
                              <div className={"font-bold text-blue-700 text-3xl"}>{data.name}</div>
                              <div className={"mt-4 font-bold text-blue-500 text-xl"}>{data.city}, {data.state}</div>
                              <div className={"font-bold mt-2 text-blue-500 text-xl"}>Contact: {data.contact}</div>
                              <div className={"mt-4"}>{data.about}</div>
                              <div className={"font-bold text-xl mt-4 mb-4 "}>
                                  Address: {data.address}
                              </div>
                              <a href={data.mapsLink} target={"_blank"}>
                                  <Button fullWidth variant={"contained"} style={{padding:"16px"} }>Open in Maps</Button>
                              </a>
                          </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
