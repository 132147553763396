import {useParams} from "react-router";
import React from 'react'
export default function AppointmentDetails(){
    const {id} = useParams()
    return(
        <div>
            {id}
        </div>
    )
}
