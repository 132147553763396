import React from 'react'
import './styles/common.css'
import hyper from '../raw/img/hyper.jpg'
import hypo from '../raw/img/hypo.jpg'
import { useNavigate } from 'react-router';




export default function Thyroid(){
    const navigator = useNavigate()
    return(

    <div>



      <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                        THYROID
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Thyroid</h4>
                        </div>
                    </div>
                  
                 </div>
{/*-----------------------------------------------------d1--------------------------------------------------*/}

                     <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Hyperthyroidism
                        </h3>
                    </div>
                



                    <div className='d-content bg-blue-300 mt-8'>
                 <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>Homeopathy treatment for Hyperthyroidism</h2>
                 </div>





                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Hyperthyroidism, also called overactive thyroid, is when the thyroid gland makes more thyroid hormones than your body needs. The thyroid is a small, butterfly-shaped gland in the front of your neck. 
                          </p></div>


            <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>

                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={hyper} alt={"disease-img"}/>
                     </div>

                     <div className='d1-symp m-auto text-center ml-10 '>

                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Hyperthyroidism ?</div>
                         <p className='ml-8'>Symptoms of hyperthyroidism can vary from person to person and may include: <br/><span className='text-blue-900 font-bold'>
                          • weight loss despite an increased appetite<br/>
                          • rapid or irregular heartbeat<br/>
                          • nervousness, irritability, trouble sleeping, fatigue<br/>
                          • shaky hands, muscle weakness<br/>
                          • sweating or trouble tolerating heat<br/>
                          • frequent bowel movements <br/>
                          •  an enlargement in the neck, called a goiter <br/><br/>
                          </span>
                          Older adults may have different symptoms, such as loss of appetite or withdrawal from people, than younger adults with hyperthyroidism.  </p>
                            <br/>
                     
                   



                   
                         <div className='text-black font-bold text-2xl  m-auto text-left p-3 '>Causes of Hyperthyroidism ?</div>
                         <p className='ml-8'> <span className='text-blue-900 font-bold'>
                          • Graves’ disease<br/>
                          • overactive thyroid nodules<br/>
                          • inflammation of the thyroid gland, called thyroiditis<br/>
                          •  too much iodine NIH external link<br/>
                          • too much thyroid hormone medicine<br/>
                          • a noncancerous tumor of the pituitary gland<br/>
                        
                          </span>  </p>
                            <br/>
                     
                  

                            
                            
                    <div>
                            <div className='  text-2xl text-justify text-green-600 font-bold '>Homeopathy Treatment  :</div>
                            <p className='   text-md font-bold '>• Lodium : For Hyperthyroidism with quick weight loss <br/>
                            • Natrum Muriaticum : For Hyperthyroidism with Irritability<br/>
                            • Lachesis Mutus : For Hyperthyroidism with extreme heat<br/>
                            • Phosphorus : For Hyperthyroidism with extreme Sensitivity, Hairfall<br/>
                            • Conium Maculatum : For Hyperthyroidism with depression<br/>
                           </p>
                        </div>
                        </div>
                        </div>
                        </div>
{/*----------------------------------------------------------d2---------------------------------------*/}
      <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Hypothyroidism
                        </h3>
                    </div>
                 </div>



                    <div className='d-content bg-blue-300 mt-8'>
                 <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>Homeopathy treatment for Hypothyroidism</h2>
                 </div>





                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Hypothyroidism, also called underactive thyroid, is when the thyroid gland doesn’t make enough thyroid hormones to meet your body’s needs. The thyroid is a small, butterfly-shaped gland in the front of your neck.
                          </p>

                 </div>
                 <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={hypo} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Hyperthyroidism ?</div>
                         <p className='ml-8'>Symptoms of hypothyroidism can vary from person to person and may include: <br/><span className='text-blue-900 font-bold'>
                          • fatigue<br/>
                          • weight gain<br/>
                          • trouble tolerating cold<br/>
                          • joint and muscle pain<br/>
                          • dry skin or dry, thinning hair<br/>
                          • heavy or irregular menstrual periods or fertility problems <br/>
                          • slowed heart rate <br/>
                          • depression<br/><br/>
                          </span>
                          </p>
                            <br/>
                   
                   



                   
                         <div className='text-black font-bold text-2xl  m-auto text-left p-3 '>Causes of Hyperthyroidism ?</div>
                         <p className='ml-8 '> <span className='text-blue-900 font-bold text-justify text-md'>
                          • Hashimoto's Disease<br/>
                          • thyroiditis, or inflammation of the thyroid<br/>
                          • congenital hypothyroidism, or hypothyroidism that is present at birth<br/>
                          • surgical removal of part or all of the thyroid<br/>
                          • radiation treatment of the thyroid<br/>
                          • some medicines<br/>
                          • Less often, hypothyroidism is caused by too much or too little iodine in the diet NIH external link or by disorders of the pituitary gland or hypothalamus<br/>
                        
                          </span>  </p>
                            <br/>
                   

                            
                            
                    <div>
                            <div className='  text-2xl text-justify text-green-600 font-bold '>Homeopathy Treatment  :</div>
                            <p className='   text-md font-bold '>Calcarea Carbonica, Sepia Officinalis, Lycopodium Clavatum, Graphites, Nux Vomica.
                           </p>
                        </div>
                        </div>
                        </div>
                        <button
                                onClick={()=>{
                                    navigator("/book-appointment")
                                }}
                                className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                              Consult Now
                            </button>

</div>





 </div>

)
}