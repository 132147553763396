import React from 'react'
import './styles/About.css'
import Clinic from "../raw/img/Clinic.png"
import Founder from "../raw/img/founder.svg"
import shubham from "../raw/img/shubham.jpg"
import pharmacy from "../raw/img/pharmacy.jpg"
import g1 from "../raw/img/aboutgallery.jpg"
import g2 from "../raw/img/aboutgallery2.jpg"
import g3 from "../raw/img/aboutgallery3.jpg"
import g4 from "../raw/img/aboutgallery4.jpg"
import g5 from "../raw/img/aboutgallery5.jpg"
import g6 from "../raw/img/aboutgallery6jpg.jpg"
import apollo from "../raw/img/Apollo.jpeg"

import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import{faEnvelope, faMobileAlt, faLocationArrow} from '@fortawesome/free-solid-svg-icons'


export default function About(){
    return(
        <div>
            <div className="page-main-container">
                <main>
                <div className='page-banner about-banner container-bn p-0'>
                    <div className='page-banner-content'>
                        <h3>
                            About
                            <span>  Dr. Roy'S Clinic</span>
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>About Us</h4>
                        </div>
                    </div>

                 </div>
{/*-------------------------------------clinic--------------------*/}
                 <div className='welcome-section  flex flex-row grid grid-cols-1 lg:grid-cols-2 '>
                 <div className=' m-auto text-center ml-10 mt-16'>
                    <h3 className='Clinic text-center text-3xl'>About Clinic</h3>

                    <div className='text-black  text-lg  m-auto text-left p-3 '> Dr. Roy's homeopathy clinic is a well-renowned clinic that's been rendering its valuable services since the year 1950. Our founder, Dr. Amal Chandra Roy, started this clinic with a mission to ensure accessible and affordable Quality Homeo Health Care by Compassionate medical Professionals to all.

                  <br/>  To Cultivate an Environment of Trust, Honesty, Mutual Respect, Equality, and Ethics.<br/>

                            <br/>   We have a team of highly talented and dedicated doctors who take care of the need of patients for all their ailments as per homeopathic philosophy.

                            <p className='text-md font-bold'>
                            Amenities<br/>
                            Hotline Support<br/>
                            Central Monitoring<br/>
                            Case Monitoring<br/>
                            24/7 365 Days<br/>
                            Our Clinic : <br/>
                            Tata Showroom Chowk , Bhagalpur Road,Dumka-814101, Jharkhand<br/>
                            We also have branch in Kolkata.</p>
                    
                    
                    
                    
                    </div>
                          </div>



                     <div className='col-md-7 col-sm-7 col-xs-7 h-full Clinic-img'>
                     <img className="clinic-img  h-full " src={Clinic} alt={"Clinic-img"}/>
                     </div>
                 </div>

{/*---------------------------------------------------------founder--------------------------------------------*/}
        <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>

            <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={Founder} alt={"founder-img"}/>
                     </div>     
                     <div className=' m-auto text-center ml-10 '>
                       <div className='text-black font-bold text-xl  m-auto text-left p-3 '>Meet our founder<span className='highight text-xl font-bold text-red-700'> Dr. Amal Chandra Roy,</span> whose compassion and dedication led to the start of this clinic.<br/>
           His service to the people of Jharkhand is cherished even now. He finished his Bachelor of Homeopathic Medicine and Surgery(B.H.M.S) . His passion to serve people ignited the same spirit in his son and has been passed to his grandson as well.
           Talking of his son,<br/>
           <span className='highight text-lg text-red-700 '>Dr. Chayan Roy</span>  is the managing director of Dr. Roy's clinic and has been rendering his valuable services ever since 1950.</div>
</div>
                        </div>
                 
  {/*-------------------------------------------------------Shubham------------------------------------- */}        
          
  <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>

    
         <div className=' m-auto text-center ml-10 '>
           <div className='text-black font-bold text-xl  m-auto text-left p-3 '>Meet <span className='highight text-xl font-bold text-red-700'> Dr. Shubham Roy,</span> who is a general physician at Dr. Roy's clinic, finished his Bachelor of Homeopathic Medicine and Surgery(B.H.M.S) from West Bengal University of health and science, Kolkata, West Bengal. <br/>
                 He believes in patient engagement policy to be the first and foremost priority. Owing to this, he makes it easy for our patients to connect with us 24/7.
                 He extends special services to every patient once they register with us, as he wishes to become their family physician and take care of their medical problem, however small or serious.

                    </div>
                          </div>

                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                    <img className=" m-auto " src={shubham} alt={"founder-img"}/>
             </div>

            </div>
          
{/*------------------------------------------------------pharmacy--------------------------------------*/}

          
          <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>

    
          <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                    <img className=" m-auto " src={pharmacy} alt={"founder-img"}/>
             </div>
         <div className=' m-auto text-center ml-10 '>
           <div className='text-black font-bold text-xl  m-auto text-left p-3 '> <span className='highight text-xl font-bold text-red-700'> Our Pharmacy </span>  houses a wide range of homeopathic medicines, and all kinds of German medicines are available. We also deliver these medicines to your doorstep upon orders. Our pharmacists or chemists are also qualified professionals, who work efficiently.
                 <br/>There are 4 chemists working full time at our clinic in Dumka. All the chemists are graduates in their particular domains. Our pharmacy works on a no-profit system where you will get genuine rates of a wide range of medicines. Amidst CoViD, we made sure that all our pharmacy chemists are fully vaccinated and follow proper guidelines issued by the Government of India and the World Health Organisation.
                 </div>
        </div>
        </div>

        {/*-----------------------gallery-------------------------------------------*/}
        <div className='d-content bg-blue-900 '>
                 <h2 className='text-3xl text-center text-white p-4 ml-4 mt-8'>Our Gallery</h2>
                 </div>
          <div className=' mt-4 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-3 h-full w-auto text-center justify-center align-center'>
          <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-9 w-72 h-80 "} src={apollo} style={{margin:"auto"}}/>
                </div>
        <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-8 "} src={g1} style={{margin:"auto"}}/>
                </div>
                <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-8 "} src={g2} style={{margin:"auto"}}/>
                </div>
                <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-8 "} src={g3} style={{margin:"auto"}}/>
                </div>
                <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-8 "} src={g4} style={{margin:"auto"}}/>
                </div>
                <div className="box col-sm-6 col-lg-3  ">
                <img className={"img-8 "} src={g5} style={{margin:"auto"}}/>
                </div>
                <div className="box col-sm-6 col-lg-3  ">
                <img className={" img-8 "} src={g6} style={{margin:"auto"}}/>
                </div>

                </div>













                </main>

            </div>


        </div>
    )
}

                    

                 

          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          













               