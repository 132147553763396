import React, {useEffect, useState} from "react"
import p1 from "../raw/img/patients/p1.png"
import p2 from "../raw/img/patients/p2.png"
import p3 from "../raw/img/patients/p3.png"
import p4 from "../raw/img/patients/p4.png"
import p5 from "../raw/img/patients/p5.png"
import p6 from "../raw/img/patients/p6.jpg"
import p7 from "../raw/img/patients/p7.jpg"
import p8 from "../raw/img/patients/p8.png"
import p9 from "../raw/img/patients/p9.png"
import p10 from "../raw/img/patients/p10.png"
import {Button, Dialog} from "@mui/material";
import {Close} from "@mui/icons-material";
import YoutubeEmbed from "../components/YoutubeEmbed/YoutubeEmbed";
import PatientsGrid from "../components/Patients/PatientsGrid";

export default function Patients(){
    return(
        <PatientsGrid/>
    )
}
