import React from 'react'
import './styles/common.css'
import { useNavigate } from 'react-router';




export default function Extemities(){
    const navigator = useNavigate()
    return(

    <div>



      <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                        EXTREMITIES
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Extremities</h4>
                        </div>
                    </div>
                  
                 </div>
{/*------------------------------------disease list--------------------------*/}


<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                      Extemities
                        </h3>
                    </div>
                 </div>



<div className='d-content bg-blue-300 mt-8'>
                 <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>EXTREMITIES WE TREAT</h2>
                 </div>



          <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
         
          <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSTEOARTHRITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>RHEUMATOID ARTHRITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>CALCANEAL SPUR</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>CARPAL TUNNEL SYNDROME</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SPONDYLITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>GOUT</div>
                </div>
                <div className="box bg-blue-900 ">
               <div className='text-xl font-bold text-white p-3 text-center'>PSORIATIC ARTHRITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>VARICOSE VEINS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSTEOMALACIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>ACUTE OSTEOMYELITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>ACUTE RHEUMATIC FEVER</div>
                </div> 
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>FROZEN SHOULDER</div>
                </div>

                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OLECRANON BURSITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl  font-bold p-3 text-white text-center'>OSTEOARTHROSIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSTEOPOROSIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>POLYMYALGIA RHEUMATICA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>RESTLESS LEG SYNDROME</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>TRIGGER-FINGER</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>LOCOMOTOR ATAXIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>ROTATOR CUFF INJURY</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSGOOD-SCHLATTER</div>
                </div>
                  
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>JUVENILE IDIOPATHIC ARTHRITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>GOLFER`S ELBOW</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>AVASCULAR NECROSIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>ATHLETE`S FOOT</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SPRAIN</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>BONE CANCER</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>PAGET`S DISEASE OF BONE</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSTEOSARCOMA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>OSTEOPENIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SYRINGOMYELIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>CARPEL TUNNEL SYNDROME</div>
                </div>
               
</div>
                <button
                                onClick={()=>{
                                    navigator("/book-appointment")
                                }}
                                className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                               Consult Now
                            </button>

</div>

    </div>


    )}