import React from 'react'
import './styles/Gyne.css'
import mens from "../raw/img/mens.jpg";
import ocys from "../raw/img/ocys.jpg";
import uf from "../raw/img/uf.webp";
import ub from "../raw/img/ub.jpg";
export default function Gyne(){
    return(
        <div>
            <div className="neuro-main-container">


                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                       GYNECOLOGY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Gynecology</h4>
                        </div>
                    </div>
                 </div>

                {/*--------------------------------------------------------*/}

                <div className='d1-symp'>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Menstrual Irregularities
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Menstrual Irregularities</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            The normal length of a woman's menstrual cycle is 28 days, but this varies between individuals. Irregular menstruation is when the length of the cycle is more than 35 days, or if the duration varies. Irregular periods, also called oligomenorrhea, can occur if there is a change in contraception method, a hormone imbalance, hormonal changes around the time of menopause, and endurance exercises.
                            Treatment for irregular periods during puberty and around menopause is not usually necessary, but if irregular periods occur during the reproductive years, medical advice may be necessary.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={mens} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Causes?</div>
                            <p className='ml-8 text-xl font-bold'>Causes includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Infection: Most commonly Bacterial H-Pylori (Helicobacter Pylori) and occasionally due to Fungal, parasitic or viral infection <br/>
                                    •	Drugs: NSAIDs, Steroids, Aspirin, etc<br/>
                                    •	Stress<br/>
                                    •	Smoking<br/>
                                    •	Excessive alcohol consumption<br/>
                                    •	Back-flow of bile into the stomach (bile reflux)<br/>
                                    •	Excess to spicy foods<br/>
                                    •	Radiation<br/>
                               </span>
                            </p>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                   The main symptom of irregular menstruation is when the cycle is longer than 35 days, or if it varies in length.
If there are changes in blood flow, or if clots appear that are more than 2.5 centimeters in diameter, this is also considered irregular.
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Bovista, Calcarea carbonica, Lachesis, Lycopodium, Pulsatilla, Sepia
                                </p>
                            </div>
                        </div>
                    </div>
                   
                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Ovarian Cyst
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Ovarian Cyst</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Ovarian cysts are closed, sac-like structures within an ovary that contain a liquid, or semisolid substance. "Cyst" is merely a general term for a fluid-filled structure, which may or may not represent a tumor or neoplasm (new growth). If it is a tumor, it may be benign or malignant. The ovary is also referred to as the female gonad.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={ocys} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                   •	Rupture of the cyst<br/>
•	Rapid growth and stretching<br/>
•	Bleeding into the cyst, or<br/>
•	Twisting of the cyst around its blood supply (known as torsion).<br/>
•	Abdominal fullness, expansion of the abdomen, or bloating,<br/>
•	Low back pain,<br/>
•	Indigestion,<br/>
•	Feeling full after eating only a small amount (early satiety),<br/>
•	Urinary urgency,<br/>
•	Difficulty with emptying the bladder,<br/>
•	Feeling an urge to defecate<br/>
•	Having difficult bowel movements, or<br/>
•	Pain with sexual intercourse.<br/>
                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Lachesis Muta, Lycopodium Clavatum, Colocynthis, Apis Mellifica, Pulsatilla Nigricans, OVARY DROP, RL-37
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Uterine Fibroid
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Uterine Fibroid</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Uterine Fibroid or myoma is a non-cancerous, benign growth in the uterus. They are also called fibromyoma, myofibroma, and fibroleiomyoma.
                            Fibroid vary in size and location, they may be Intramural (inside the cavity), Submucosal (in the wall of the uterus), Subserosal (on the outer side of the uterus), or in the cervix at times.
                            Fibroid are typically found during the middle and later reproductive years and are commonly seen in overweight women.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={uf} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                    •	Excessive, heavy, irregular, or prolonged bleeding<br/>
•	Uterine heaviness<br/>
•	Pain in the lower abdomen in general<br/>
•	Painful sexual intercourse<br/>
•	Bloating<br/>
•	Infertility<br/>
•	Abdominal lump<br/>
•	Urinary frequency<br/>
•	Bowel pressure with constant urging for stool<br/>

                                </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Thlaspi Bursa Pastoris, Calcarea Carb, Belladonna, Sepia, Ustilago Maydis, Sabina, FIBROID DROP, RL-37
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*---------------------------disease5----------------------------------*/}
                    <div className='  d-banner container-bn-d  p-0 mt-8'>
                        <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                            <h3>
                                Uterine Bleeding
                            </h3>
                        </div>
                    </div>
                    <div className='d-content bg-blue-300 '>
                        <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Uterine Bleeding</h2>
                    </div>
                    <div className='d-descp text-lg mr-10 mt-8'>
                        <p>
                            Abnormal uterine bleeding is when bleeding in between the monthly periods, or when there is bleeding for too long or it is an extremely heavy flow. Normal menstrual flow typically lasts approximately five days and occurs every 21 to 35 days.
                        </p>
                    </div>
                    <div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                        <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                            <img className=" m-auto " src={ub} alt={"disease-img"}/>
                        </div>
                        <div className='d1-symp m-auto text-center ml-10 '>
                            <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                            <p className='ml-8 text-xl font-bold'>Symptoms includes :<br/><br/>

                                <span className='text-blue-900 mt-0 font-bold'>
                                  •	Menorrhagia (heavy menstrual bleeding)<br/>
•	Bleeding at unusual times (between periods, after sex, during menopause)<br/>
•	Unusually long periods (seven days or longer)<br/>
•	Inconsistent menstrual cycles<br/>

                               </span>
                            </p>
                            <br/>
                            <div>
                                <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                                <p className='   text-xl font-bold '>
                                    Sabina – For Profuse Bleeding<br/>
                                    Thlaspi – For Bleeding with Prolonged Periods<br/>
                                    Phosphorus – For Inter-menstrual Bleeding<br/>
                                    Ferrum Met – For Pale, Watery, Profuse Blood Flow<br/>
                                    Trillium Pendulum – For Profuse and Bright Red Bleeding<br/>
                                    Sepia – When Bleeding arises after Sexual Intercourse<br/>
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>

                    {/*------------------------------------------------------------------*/}
                   
               

                    <div className='d-content bg-blue-300 mt-8'>
                   <h2 className='text-2xl text-center text-white p-2  font-bold text-center'> MORE GYNECOLOGICAL PROBLEMS WE TREAT</h2>
                   </div>
  
  
  
            <div className=' mt-4 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-3 h-full w-auto text-center p-4 justify-center align-center'>
           
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>MENSTRUAL DISORDERS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>TURNER SYNDROME</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>DYSMENORRHEA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>LEUCORRHOEA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>AMENORRHOEA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CERVICITIS</div>
                  </div>
                  <div className="box bg-blue-900 ">
                 <div className='text-xl font-bold text-white p-3 text-center'>ENDOMETRIOSIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>MENORRHAGIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>METRORRHAGIA</div>
                  </div>
                 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>VAGINITIS</div>
                  </div> 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PAGET`S DISEASE OF THE NIPPLE</div>
                  </div>
  
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'> VAGINAL THRUSH</div>
                  </div>
                 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>POSTNATAL DEPRESSION</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>BREAST LUMP</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>AGALACTIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HIRSUTISM</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>FEMALE STERILITY</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>HOT FLASHES MENOPAUSE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>DYSPAREUNIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>VULVITIS</div>
                  </div>
                 
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ANTEPARTUM HAEMORRHAGE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>GONORRHEA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>MASTITIS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>UTERINE PROLAPSE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PRE-ECLAMPSIA</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>POSTPARTUM HEMORRHAGE</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>CERVICAL POLYPS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>SEXUAL DESIRE DISORDER</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>MORNING SICKNESS</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>POSTMENOPAUSAL BLEEDING</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>ABNORMAL UTERINE BLEEDINGABNORMAL UTERINE BLEEDING</div>
                  </div>
                  <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
                 <div className='text-xl font-bold p-3 text-white text-center'>PREMENSTRUAL SYNDROME <br/>OR<br/> PREMENSTRUAL TENSION SYNDROME</div>
                  </div>
                  </div>
                  
                  
                 
                    
                 
 
                  <button
                                  onClick={()=>{
                                      navigator("/book-appointment")
                                  }}
                                  className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                               Consult Now
                              </button>
  
  </div>
  
      </div>
  
  
      )}

    
