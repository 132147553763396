import React from 'react'
import './styles/common.css'
import { useNavigate } from 'react-router';




export default function Spine(){
    const navigator = useNavigate()
    return(

    
        <div>



      <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                         SPINE PROBLEMS
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Spine problems</h4>
                        </div>
                    </div>
                  
                 </div>
{/*------------------------------------disease list--------------------------*/}


<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner text-center mt-6 neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                      Spine Problems
                        </h3>
                    </div>
                 </div>



<div className='d-content bg-blue-300 mt-8'>
                 <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>SPINE PROBLEMS WE TREAT</h2>
                 </div>



          <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
         
          <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>BACKACHE</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>LUMBAR SPONDYLITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>LOW BACK PAIN OR LUMBAGO</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SCIATICA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SCOLIOSIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SACROILITIS</div>
                </div>
                <div className="box bg-blue-900 ">
               <div className='text-xl font-bold text-white p-3 text-center'>PIRIFORMIS SYNDROME</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>PILONIDAL CYST</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>COCCYDYNIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SPINAL STENOSIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>HERNIATED DISC / <br/>SLIPPED DISC</div>
                </div> 
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>RICKETS</div>
                </div>

                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>PARAPLEGIA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl  font-bold p-3 text-white text-center'>SPINA BIFIDA</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>TRANSVERSE MYELITIS</div>
                </div>
                <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
               <div className='text-xl font-bold p-3 text-white text-center'>SPINAL CORD TUMOR</div>
                </div>
               
                  
               
</div>
                <button
                                onClick={()=>{
                                    navigator("/book-appointment")
                                }}
                                className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                             Consult Now
                            </button>

</div>

    </div>


    )}