import React from 'react'
import './styles/Depress.css'
import d1 from '../raw/img/depression2.jpg'

export default function Depress(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                        Depression
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Depression</h4>
                        </div>
                    </div>
                  
                 </div>



                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Depression
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl ml-8  mt-8'>Homeopathy Treatment for Depression</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Depression is a chronic, long-lasting or recurring, and treatable disorder where the patient experiences sadness, worthlessness, loss of motive, loss of interest, and/or hopelessness.<br/><br/>
A depressive disorder is an illness that involves mood and thoughts and in simple words, can be expressed as a persistent sad mood. Depression affects the way a person eats and sleeps, the way one feels about oneself, and the way one thinks about things.<br/><br/>
Depression results from complex partly understood biochemical changes in the brain, which are influenced and supported by multiple causes inclusive of the genetic trait, circumstantial factors, hormonal factors, socio-personal reasons, etc. Increased levels of brain chemicals called neurotransmitters, such as serotonin and norepinephrine are found to improve the state of depression.<br/>
Depression is much more common than we believe. However, to get precise figures in terms of its prevalence is difficult as many of them go undetected and untreated. Women are twice more often affected by depression than men.

                          </p>

                 </div>
                 <div  className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Common Symptoms of Depression </div>
                         <p className='ml-8'><span className='text-blue-900 font-bold'>
                         •	Persistent sadness, anxiety, or "empty" mood, and unexplained crying spells<br/>
•	Feelings of hopelessness, pessimism, guilt feeling, worthlessness<br/>
•	Loss of interest or pleasure in hobbies and activities that were once enjoyed, including sex, social withdrawal<br/>
•	Loss of energy, persistent lethargy<br/>
•	Difficulty concentrating, remembering, making decisions<br/>
•	Insomnia, early-morning awakening, or oversleeping<br/>
•	Appetite and/or weight loss or overeating and weight gain<br/>
•	Thoughts of death or suicide; suicide attempts<br/>
•	Restlessness, irritability, anger, agitation<br/>
•	Persistent physical symptoms that do not respond to treatment, such as headaches, digestive disorders, and chronic pain<br/>
</span> </p>
                            <br/>
                     
                    

                            
                      
                     <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Ignatia, Aconite, Arsenic album, stramonium e.t.c</p>
                        </div>
                        </div>
                         
</div>

                         

                               
                            
                    
                      
                      
         </div>
                                 

                                 
                           
                        
                                   
                
        
        
        
                          

                           
               
   </div>     
          
        
         
    )
}