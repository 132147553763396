import React from 'react'
import './styles/Lungs.css'
import d0 from '../raw/img/asthma.jpg'
import d1 from '../raw/img/bronchitis.jpg'
import d2 from '../raw/img/pneumonia.jpg'
import d3 from '../raw/img/tuberclosis.jpg'
import d4 from '../raw/img/lungcancer.jpg'
import d5 from '../raw/img/cough.jpg'

export default function Lungs(){
    return(
        <div>
            <div className="neuro-main-container">
                
                
                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>
                            
                          RESPIRATORY
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Respiratory</h4>
                        </div>
                    </div>
                  
                 </div>
                 <div className='  d1-banner container-bn-d1  p-0 mt-8'>
                    <div className='neuro-d1-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Asthma
                        </h3>
                    </div>
                 </div>
                
                 <div className='d1-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-8 mt-8'>Homeopathy Treatment for Asthma</h2>
                 </div>
                 <div className='d1-descp text-lg mr-10 mt-8'>
                 <p>Asthma is a chronic inflammation of the bronchial tubes (airways) that causes swelling and narrowing (constriction) of the airways.<br/> The result is difficulty breathing. The bronchial narrowing is usually either totally or at least partially reversible with treatments.</p>
                 </div>
                 
                           
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d0} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are symptoms of Asthma ?</div>
                         <p className='ml-8'><span className='text-xl font-bold'>Asthma signs and symptoms include:</span> <br/>
•	Shortness of breath:<br/>
•	Chest tightness or pain:<br/>
•	Wheezing when exhaling, which is a common sign of asthma in children:<br/>
•	Trouble sleeping caused by shortness of breath, coughing or wheezing:<br/>
•	Coughing or wheezing attacks that are worsened by a respiratory virus, such as a cold or the flu:<br/>
 </p>
                            <br/>
                               <div className='font-bold text-lg m-auto text-left '>Signs that Asthma is probably worsening include : </div>
                           <p className='text-xl ml-10 mt-2'>     
                           •	Asthma signs and symptoms that are more frequent and bothersome<br/>
•	Increasing difficulty breathing, as measured with a device used to check how well our lungs are working (peak flow meter)<br/>
•	The need to use a quick-relief inhaler more often<br/>
</p>
                            
<div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Diagnosis of Asthma</div>
                         <p className='ml-8'><span className='text-xl font-bold'>Pulmonary function test:</span> <br/>
•	Spirometry<br/>
•	Challenge test<br/>
•	Exhaled Nitric oxide test<br/>
•	Complete Blood count<br/>
•	Chest and Sinus X-rays<br/>
•	CT scan<br/>
•	GERD assessment test
<br/>
 </p>







                
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment : </div>
                            <p className='   text-xl font-bold '>Arsenic Album, Spongia Tosta, Antimonium Tartaricum, Ipecac, Sambucus Nigra, Blatta Orientalis and Bromium, Bromium, Lobelia Inflata</p>
                        </div>
                        </div>
                        </div>
                          {/*--------------disease2-----------------------------------------*/}
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Bronchitis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl ml-4  mt-8'>Homeopathy Treatment for Bronchitis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> Bronchitis simply means an inflammation of the bronchus, the windpipe.<br/> This is usually caused by infection (viral, bacterial, fungal, etc) or allergy or both. This condition is widely prevalent throughout the world and is a frequent cause to disturb our daily work.
                       Bronchitis is slightly more common in women when compared to males. It is more common amongst the younger age-group compared to the adult population.
                        </p>
                 </div>
                 < div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Bronchitis </div>
                         <p className='ml-8'><br/><span className='text-blue-900 font-bold'> 
                         •	Cough (with or without mucus)<br/>
•	Fever<br/>
•	Chest pain,<br/>
•	Difficulty in breathing. (dyspnea)<br/>
•	Throat pain<br/>
•	Nose congestion,<br/>
•	Body ache, joint pain, tiredness (usually due to infection).<br/>

                                 <br/></span> </p>
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathic Treatment of Bronchitis </div>
                            <p className='   text-xl font-bold '>Antimonium Tartaricum, Calcarea Carbonica, Kali Bichromicum, Hepar Sulph, Silicea, Kali Bichromicum.</p>
                        </div>
                        </div>

                     
                </div>  

                
                          {/*--------------disease4-----------------------------------------*/}      
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Pneumonia                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for  Pneumonia</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Pneumonia is an infection that inflames the air sacs in one or both lungs.<br/> The air sacs may fill with fluid or pus (purulent material), causing cough with phlegm or pus, fever, chills, and difficulty breathing. A variety of organisms, including bacteria, viruses and fungi, can cause pneumonia. It is most serious for infants and young children, people older than age 65, and people with health problems or weakened immune systems</p>

                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Pneumonia</div>
                         <p className='ml-8'> <br/><span className='text-blue-900 font-bold'>
                         •	Chest pain when breathe or cough<br/>
•	Confusion or changes in mental awareness (in adults age 65 and older)<br/>
•	Cough, which may produce phlegm<br/>
•	Fatigue<br/>
•	Fever, sweating and shaking chills<br/>
•	Lower than normal body temperature (in adults older than age 65 and people with weak immune systems)<br/>
•	Nausea, vomiting or diarrhea<br/>
•	Shortness of breath<br/>



                                
                                
                                
                                </span> </p>
                            <br/>
                               <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for of Pneumonia </div>
                            <p className='   text-xl font-bold '>Hepar Sulph, Antimonium Tart, Arsenic Album, Bryonia</p>
                        </div>
                        </div>
                        </div>            
                
        
        
        
        
                          {/*--------------disease3-----------------------------------------*/}   
                          <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Tuberculosis
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Tuberculosis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Tuberculosis (TB) is a contagious infection that usually attacks on the lungs.<br/> It can also spread to other parts of the body, like brain and spine. A type of bacteria called Mycobacterium tuberculosis causes it. <br/><br/><br/>
               <span className='text-lg text-red-500 font-bold'>  •	Latent TB </span> - In this condition, you have a TB infection, but the bacteria remain in your body in an inactive state and cause no symptoms. Latent TB, also called inactive TB or TB infection, isn't contagious. It can turn into active TB, so treatment is important for the person with latent TB and to help control the spread of TB. An estimated 2 billion people have latent TB.<br/><br/>
               <span className='text-lg text-red-500 font-bold'>  • Active TB </span> - This condition makes you sick and in most cases can spread to others. It can occur in the first few weeks after infection with the TB bacteria, or it might occur years later.<br/>
</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d3} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Signs and Symptoms of Active TB include:</div>
                         <p className='ml-8'><br/>
                         <span className='text-blue-900 font-bold'>
                         •	Coughing that lasts three or more weeks<br/>
•	Coughing up blood<br/>
•	Chest pain, or pain with breathing or coughing<br/>
•	Unintentional weight loss<br/>
•	Fatigue<br/>
•	Fever<br/>
•	Night sweats<br/>
•	Chills<br/>
•	Loss of appetite<br/>
<br/>

                               </span>
                               </p>
                            <br/>
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for Tuberculosis </div>
                            <p className='   text-xl font-bold '>Sulphur, Arsenic album, Arsenic iod, Tuberculinum, Phosphorus, Iodum, Silicea, Sangunaria, Calcarea carb, Lycopodium, Bryonia</p>
                        </div>
                        </div>
                        </div>            
                 {/*--------------disease4-----------------------------------------*/}   
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        Lung Cancer
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Lung Cancer</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Lung cancer is a type of cancer that begins in the lungs. People who smoke are at greater risk of getting lung cancer.</p> 
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Lung Cancer</div>
                         <p className='ml-8'><br/>
                         <span className='text-blue-900 font-bold'>
                         •	Coughing that lasts three or more weeks<br/>
                         •	A new cough that doesn't go away<br/>
•	Coughing up blood, even a small amount<br/>
•	Shortness of breath<br/>
•	Chest pain<br/>
•	Hoarseness<br/>
•	Losing weight without trying<br/>
•	Bone pain<br/>
•	Headache<br/>

<br/>

<span className='text-lg text-red-500 font-bold'>  • Small cell lung cancer </span> - Small cell lung cancer occurs almost exclusively in heavy smokers and is less common than non-small cell lung cancer.<br/><br/>
               <span className='text-lg text-red-500 font-bold'>  •	Non-small cell lung cancer  </span> - Non-small cell lung cancer is an umbrella term for several types of lung cancers. Non-small cell lung cancers include squamous cell carcinoma, adenocarcinoma and large cell carcinoma.<br/>
                               </span>
                               </p>
                            <br/>
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment for  Lung Cancer </div>
                            <p className='   text-xl font-bold '>Arsenicum Iodatum, Cobaltum Muriaticum, Ionized Radiation, Acalypha Indica, Ammonium Carbonicum, Anthemis Nobilis, Apis Mellifica, Aranea Diadema, Argentum Metallicum, Argentum Nitricum, Arsenicum Album, Bromium. </p>
                        </div>
                        </div>
                        </div>
                         {/*--------------disease5-----------------------------------------*/}   
                         <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            
                        WHOOPING COUGH OR PERTUSIS
                        </h3>
                    </div>
                 </div>
                
                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Whooping</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> The patient, a child, has paroxysms of cough which teriminate into a whoop and frequently in vomiting. There is some fever but the only sign in the lungs is that of a little bronchial catarrh. The disease condition is WHOOPING COUGH or PERTUSIS.
Haemophilus pertussis is the causal organism which is present in abundance is the respiratory mucous during the catarrhal stage.
<br/>
</p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d5} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Signs and Symptoms </div>
                         <p className='ml-8'><br/>
                         <span className='text-blue-900 font-bold'>
                         •	Runny nose<br/>
•	Nasal congestion<br/>
•	Red, watery eyes<br/>
•	Fever<br/>
•	Cough<br/>

After a week or two, signs and symptoms worsen. Thick mucus accumulates inside your airways, causing uncontrollable coughing. Severe and prolonged coughing attacks may:

<br/>

•	Provoke vomiting<br/>
•	Result in a red or blue face<br/>
•	Cause extreme fatigue<br/>
•	End with a high-pitched "whoop" sound during the next breath of air<br/>


                               </span>
                               </p>
                            <br/>
                               
                            
                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment </div>
                            <p className='   text-xl font-bold '>Mephitis putorius, Belladonna, Pertussinum or coqueluchinum, Corallium rubrum, Drosera rotundifolia, Spongia tosta etc</p>
                        </div>
                        </div>
                        </div>            
                
        
        
        
        
        
           {/*-----------------------------------------------------------------*/}
           <div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE RESPIRATORY PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>
     
      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>COPD</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>SARS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>SINUSITIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>INFLUENZA</div>
            </div>
        
        
        
            
</div>
<button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>
        </div>
          </div>
    )
}