import React from "react"
import check from '../raw/img/check.png'
import {useNavigate, useParams} from "react-router";
import {Button} from "@mui/material";
export default function BookingDone(){

    const {id} = useParams()
    const navigator = useNavigate()

    return(
        <div>
            <div className={"bg-gray-100 p-2 md:m-8 m-2 rounded-xl text-center"}>
               <div className={"mt-16"}><img src={check} alt={"check.png"} width={120} style={{margin:"auto"}}/></div>
                <div className={"mt-12 mb-12 text-md bg-white rounded-lg pl-2 pr-2 pt-4 pb-4 font-bold"}>Booking Ref.<br/> {id}</div>
                <div className={"text-3xl font-bold mt-4 mb-4"}>Booking request sent!</div>
                <div className={"text-md mt-4 mb-4"}>Appointment request has been recorded. Please wait, our representative will contact you within 24 hours!</div>
                <div className={"mb-16"}>
                    <Button
                        variant={"contained"}
                        onClick={(_=>{
                            navigator("/")
                        })}
                        style={{padding:"16px 10% 16px 10%", margin:"16px 0"}}>
                        Go back
                    </Button>
                </div>
            </div>
        </div>
    )
}
