import React, {useEffect} from 'react'
import './styles/Cardio.css'
import d1 from '../raw/img/hypertension.jpg'
import d2 from '../raw/img/cardiacarrest.jpg'
import d3 from '../raw/img/atero.jpg'
import d4 from '../raw/img/block.jpg'
import d5 from '../raw/img/endo.jpg'
export default function Cardio(){

    useEffect(()=>{
        return()=>{window.scrollTo(0, 0)}
    },[])

    return(
        <div>
            <div className="neuro-main-container">


                <div className='neuro-banner about-banner container-bn p-0'>
                    <div className='neuro-banner-content'>
                        <h3>

                          CARDIOVASCULAR
                        </h3>
                    </div>
                    <div className='banner-ls container-fluid mt-0'>
                        <div className='container'>
                            <ol className='cont-list'>
                                <li className='font-bold text-white '>
                                    <a href='./src/pages/Home.js'>
                                        Home
                                    </a>
                                </li>
                            </ol>
                            <h4 className='text-white font-bold '>Cardiovascular</h4>
                        </div>
                    </div>

                 </div>
                 <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                        Hypertension
                        </h3>
                    </div>
                 </div>

                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Hypertension</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Raised blood pressure is known as hypertension. As we know, the blood pressure of an individual tends to increase with advancing age. So, the term hypertension will be used when blood pressure exceeds the normal range for a particular age group. Unattended blood pressure can lead to several serious complications including stroke, heart disease, kidney failure, etc.
                       <br/><br/>
                        Hypertension is very common in the general population. However, in 95% of cases, a specific underlying cause of hypertension cannot be pointed out. This hypertension is labeled as essential hypertension and is believed to be related to genetic and heredity factors.                         </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d1} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'> Symptoms: <br/><br/>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	Severe headaches <br/>
•	Nosebleed<br/>
•	Fatigue or confusion<br/>
•	Vision problems<br/>
•	Chest pain<br/>
•	Difficulty breathing<br/>
•	Irregular heartbeat<br/>
•	Blood in the urine<br/>
<br/>

                               </span>
                               </p>
                            <br/>
                            <p className='ml-8 text-xl text-red-500 font-bold'> <br/>
                            Causes of Primary Hypertension :<p/> <br/>
                         <span className='text-blue-900 font-bold'>
                         •	Smoking<br/>
•	Being overweight or obese<br/>
•	Lack of physical activity<br/>
•	Too much salt in the diet<br/>
•	Too much alcohol consumption (more than 1 to 2 drinks per day)<br/>
•	Stress<br/>
•	Oldage<br/>
•	Genetics<br/>
<br/>


                               </span>
                               </p>


                               <p className='ml-8 text-xl text-red-500 font-bold'> <br/>
                               Causes of Secondary hypertension:<p/> <br/>
                         <span className='text-blue-900 font-bold'>
                         •	Obstructive sleep<br/>
•	Kidney problems<br/>
•	Adrenal gland tumors<br/>
•	Thyroid problems<br/>
•	Certain defects you're born with (congenital) in blood vessels<br/>
•	Certain medications, such as birth control pills, cold remedies, decongestants, over-the-counter pain relievers and some prescription drugs<br/>
•	Illegal drugs, such as cocaine and amphetamines<br/>
<br/>


                               </span>
                               </p>



                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Allium Sativum 30, Aconitum Napellus 30, Baryta Mur 30, Crataegus Oxyacantha.</p>
                        </div>
                        </div>
                        </div>

                {/*----------------disease-2-------------------------------------------------*/}



                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                        Cardiac Arrest
                        </h3>
                    </div>
                 </div>

                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Cardiac Arrest</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Cardiac arrest is sudden and unexpected stoppage of circulation as a result of an electrical disturbance in the heart, either ventricular fibrillation or asystole. No pulse of heart sound is heared. After a variable period. Respiration ceases and pupils dilate. Effective circulation must be established within three minutes or else permenant damage to the brain and usually death occurs. </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d2} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'>Signs of sudden cardiac arrest are immediate and drastic and include:<br/><br/>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	Chest discomfort<br/>
•	Shortness of breath<br/>
•	Weakness<br/>
•	Fast-beating, fluttering or pounding heart (palpitations)<br/>


<br/>

                               </span>
                               </p>
                            <br/>
                            <p className='ml-8 text-xl text-red-500 font-bold'> <br/>
                            Sometimes other signs and symptoms occur before sudden cardiac arrest. These might include:<p/> <br/>
                         <span className='text-blue-900 font-bold'>
                         •	Smoking<br/>
•	Being overweight or obese<br/>
•	Lack of physical activity<br/>
•	Too much salt in the diet<br/>
•	Too much alcohol consumption (more than 1 to 2 drinks per day)<br/>
•	Stress<br/>
•	Oldage<br/>
•	Genetics<br/>
<br/>
</span>
</p>






                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Lachesis mutus, Lacticum acidum</p>
                        </div>
                        </div>
                        </div>

                {/*----------------disease-3-------------------------------------------------*/}





                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>
                            Arteriosclerosis
                        </h3>
                    </div>
                 </div>

                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Arteriosclerosis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Arteriosclerosis is a hardening of the arteries, its occurs when the blood vessels that carry oxygen and nutrients from your heart to the rest of the body (arteries) become thick and stiff sometimes
                 restricting blood flow to organs and tissues. Healthy arteries are flexible and elastic, but over time, the walls of arteries can harden.</p>



                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d3} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>Symptoms of Arteriosclerosis</div>
                         <p className='ml-8'><br/>


<span className='text-lg text-red-500 font-bold'>  • If there is atherosclerosis in the heart arteries </span> , symptoms such as chest pain or pressure (angina) occures.<br/><br/>
               <span className='text-lg text-red-500 font-bold'> •	If there is atherosclerosis in the arteries leading to brain</span>
               ,  signs and symptoms are; sudden numbness or weakness in arms or legs, difficulty speaking or slurred speech, temporary loss
               of vision in one eye, or drooping muscles in your face. These signal a transient
                ischemic attack (TIA), which, if left untreated, may progress to a stroke.<br/>

                <span className='text-lg text-red-500 font-bold'>  • If there is atherosclerosis in the arteries in arms and legs </span> , symptoms of peripheral artery disease, such as leg pain when walking (claudication) occur.<br/>
                <span className='text-lg text-red-500 font-bold'>  • If there is atherosclerosis in the arteries leading to the kidneys </span>  , develop high blood pressure or kidney failure.<br/>
                               </p>
                            <br/>


                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment  </div>
                            <p className='   text-xl font-bold '> Baryta Mur, Arnica Montana, Aurum met, Ergotin, Spigelia </p>
                        </div>
                        </div>
                        </div>


                {/*--------------------------------------d4-----------------------------*/}
                <div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                        Heart Block
                        </h3>
                    </div>
                 </div>

                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Heart Block</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p> Heart block is the name given to the symptoms produced by disease of the auricular-ventricular bundle or bundle of His. such disease interferes with the normal stimulus conduction from the auricles to ventricles.
A partial heart block happens when the electrical impulses are delayed or stopped, preventing the heart from beating regularly.
A complete heart block is when the electrical signals stop completely. The heartbeat will drop to about 40 times per minute.
 </p>
                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d4} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>


                     <p className='ml-8'>Types <br/> There are three types of Heart Block<br/>
                <span className='text-lg text-red-500 font-bold'>  First-degree heart block  </span> : its involves minor heartbeat disruptions, such as skipped beats. It is the least serious type of heart block, and it does not generally require treatment<br/><br/>
               <span className='text-lg text-red-500 font-bold'> Second-degree heart block</span> : its occurs when some electrical signals never reach the heart, causing dropped or skipped beats. The patient may feel dizzy, and they may need a pacemaker. The ventricle may not contract, as the atrial impulse did not reach the ventricles.<br/>

                <span className='text-lg text-red-500 font-bold'> Third-degree or complete heart block </span> : its happen when electrical signals do not travel between the upper and lower chambers of the heart. It is more common in patients with heart disease. Without a pacemaker, there is a serious risk of heart attack<br/>

                               </p>
                            <br/>

                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'>
                         <span className='text-blue-900 mt-0 font-bold'>
                         •	slow or irregular heartbeats, or palpitations<br/>
•	shortness of breath<br/>
•	lightheadedness and fainting<br/>
•	pain or discomfort in the chest<br/>
•	difficulty in doing exercise, due to the lack of blood being pumped around the body
<br/>


<br/>

                               </span>
                               </p>
                            <br/>








                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '>Cactus, Crataegus, Digitalis, Kalium carbonicum, Spigelia

</p>
                        </div>
                        </div>
                        </div>

{/*-------------------------------------d5-------------------------------------------------*/}
<div className='  d-banner container-bn-d  p-0 mt-8'>
                    <div className='neuro-d-banner neuro-banner-content text-5xl font-bold'>
                        <h3>

                        Endocarditis
                        </h3>
                    </div>
                 </div>

                 <div className='d-content bg-blue-300 '>
                 <h2 className='text-3xl  ml-4 mt-8'>Homeopathy Treatment for Endocarditis</h2>
                 </div>
                 <div className='d-descp text-lg mr-10 mt-8'>
                 <p>Endocarditis is inflammation of heart's inner lining, called the endocardium. It's usually caused by bacteria. When the inflammation is caused by infection, the condition is called infective endocarditis. Endocarditis is uncommon in people with healthy hearts</p>                 </div>
                 <div div className='flex flex-row grid grid-cols-1 lg:grid-cols-2'>
                 <div className='col-md-7 col-sm-7 col-xs-7 m-10   '>
                     <img className=" m-auto " src={d5} alt={"disease-img"}/>
                     </div>
                     <div className='d1-symp m-auto text-center ml-10 '>
                         <div className='text-red-600 font-bold text-2xl  m-auto text-left p-3 '>What are the Symptoms ?</div>
                         <p className='ml-8 text-xl font-bold'>Symptoms of Endocarditis , includes :<br/><br/>

                         <span className='text-blue-900 mt-0 font-bold'>
                         •	Heart murmur, which is an abnormal heart sound of turbulent blood flow through the heart<br/>
•	pale skin<br/>
•	Fever or chills<br/>
•	Night sweats<br/>
•	Muscle or joint pain<br/>
•	Nausea or decreased appetite<br/>
•	A full feeling in the upper left part of abdomen<br/>
•	Unintentional weight loss<br/>
•	Swollen feet, legs, or abdomen<br/>
•	Cough or shortness of breath<br/>
•	Aching joints and muscles<br/>
•	Chest pain when you breathe<br/>
•	Fatigue<br/>
•	Flu-like symptoms, such as fever and chills<br/>
•	Night sweats<br/>
•	Shortness of breath<br/>
•	Swelling in feet, legs or abdomen<br/>
•	A new or changed heart murmur, which is the heart sound made by blood rushing through the heart<br/>
<br/>


<br/>

                               </span>
                               </p>
                            <br/>






                            <div>
                            <div className='  text-2xl text-left text-green-600 font-bold '>Homeopathy Treatment   : </div>
                            <p className='   text-xl font-bold '> Aurum metallicum, Kalmia latifolia, Spegelia, Veratrum viride  </p>
                        </div>
                        </div>
                        </div>

  {/*-------------------------------------------------------------------------------------------------*/}
                        <div className='d-content bg-blue-300 mt-8'>
             <h2 className='text-2xl text-center text-white p-2  font-bold text-center'>MORE CARDIO  PROBLEMS WE TREAT</h2>
             </div>



      <div className=' mt-2 grid grid-cols-2  sm:grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-2 h-full w-auto text-center p-4 justify-center align-center'>

      <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>ANGINA PECTORIS</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CARDIO MYOPATHY</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>STROKE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>RHEUMATIC HEART DISEASE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>MITRAL REGURGITATION</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CHEST PAIN</div>
            </div>
            <div className="box bg-blue-900 ">
           <div className='text-xl font-bold text-white p-3 text-center'>HYPOTENSION</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CORONARY ARTERY DISEASE</div>
            </div>
            <div className="box cursor-pointer  bg-blue-900 "  onClick={()=>{navigator("/book-appointment")}}>
           <div className='text-xl font-bold p-3 text-white text-center'>CONGENTIAL HEART DISEASE</div>
            </div>




</div>
            <button
                            onClick={()=>{
                                navigator("/book-appointment")
                            }}
                            className={"mt-6 ml-6  px-12 bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 font-bold text-white    text-center transition transform  hover:-translate-y-1 motion-reduce:transition-none motion-reduce:transform-none"}>
                         Consult Now
                        </button>

</div>

</div>


)}



