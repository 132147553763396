import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import doctorImg from "../raw/img/doctor.webp"
import {useState} from "react";
import { getAuth, signInAnonymously} from "firebase/auth";
import {getAuthUser} from "../firebase/helper";
import {uploadAppointmentToFirestore} from "../firebase/firestore";
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate} from "react-router";
import React from 'react'

export default function BookAppointment(props){
    const navigator = useNavigate()

    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [age, setAge] = useState(0)
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [issue, setIssue] = useState("")
    const [gender, setGender] = useState("female")

    const [inputErrorText, setInputErrorText] = useState("")
    const [humanVerified, setHumanVerified] = useState(false)

    /**
     * Validates the inputs and uploads the content to the database.
     */
    const validateInput = () => {
        if(name)
            if(age > 0)
                if(phone.length >= 10)
                    if(address)
                        if(issue)
                            if(humanVerified)
                                uploadAppointmentToFirestore({
                                    name: name,
                                    age:age,
                                    gender: gender,
                                    phone:phone,
                                    email:email,
                                    address: address,
                                    issue: issue,
                                    status: 0,
                                    registeredOn: new Date()
                                })
                                    .then(async (result) => {
                                        navigator(`/booking-done/${result.id}`)
                                    })
                                    .catch((err)=>{
                                        console.log(err)
                                    })
                                else setInputErrorText("Please verify that you are not a robot!")
                        else setInputErrorText("Please enter a valid issue!")
                    else setInputErrorText("Please enter a valid address!")
                else setInputErrorText("Please enter a valid phone number!")
            else setInputErrorText("Please enter a valid age!")
        else setInputErrorText("Please enter a valid name!")
    }

    const onCaptchaChanged = (val)=>{
        setHumanVerified(true)
        console.log(val)
    }

    const submitClicked = () =>{
        /**
         * Signing in the user anonymously (without unique credentials). --- This is for temporary use.
         * If the use wants to log-in properly then it can do so.
         * (This functionality will be included at a later phase of the project)
         */
        if(!getAuthUser()){
            console.log("Authenticating user")
            const auth = getAuth()
            signInAnonymously(auth)
                .then((r)=>{
                    //ToDo: Register appointment request to database!
                    console.log(r) // --- This is temporary.
                })
                .catch((e)=>{
                    alert(`Error: ${e.code}\n ${e.message}`)
                })
        }
        validateInput()
    }

    return(
        <div className={"flex flex-row grid grid-cols-1 lg:grid-cols-2 gap-4 h-full text-center justify-center align-center"}>
            <div className={"h-full p-10"}>
                <img src={doctorImg} style={{width:"100%"}}/>
            </div>
            <div className={"text-left p-8"}>
                <div className={"bg-indigo-50 rounded-2xl p-8 text-left"}>
                    <div className={"flex md:flex-column flex-rows items-center"}>
                        <img src="https://img.icons8.com/color/96/000000/consultation.png" alt={"hello.png"}/>
                        <div className={"ml-8"}>
                            <span className={"text-3xl text-blue-800"}><span className={"font-bold"}>Hello, </span>lets get in touch!</span>
                            <br/><div className={"m-4"}/>
                            <span className={"text-blue-600"}>Please provide us with your details and query and we will contact you within 24-hours for booking confirmation and timing.</span>
                        </div>
                    </div>
                </div>
                {(inputErrorText.length > 0)?

                    <div className={"bg-red-50 rounded-2xl p-8 text-left mt-8"} >
                        <div className={"flex flex-column items-center"}>
                            <div className={"ml-8"}>
                                <span className={"text-3xl text-red-600"}><span className={"font-bold"}>Error, </span>invalid input!</span>
                                <br/><div className={"m-4"}/>
                                <span className={"text-red-600"}>{inputErrorText}</span>
                            </div>
                        </div>
                    </div>
                    : <br/>
                }


                <div className={"text-left mx-4 mt-10 font-bold"}>
                    <span className={"text-3xl"}>About you</span><br/><div className={"m-8"}/>
                    <TextField
                        label={"Full Name"}
                        fullWidth
                        inputMode={"text"}
                        style={{margin:"2% 0"}}
                        required
                    onChange={(e)=>{
                        setName(e.target.value)
                    }}
                    />
                    <div className={"flex flex-row grid grid-cols-1 lg:grid-cols-2 gap-2 items-center my-4"}>
                        <TextField
                            label={"Age"}
                            type={"number"}
                            style={{width:"80%", margin:"0 0 5% 0"}}
                            inputMode={"numeric"}
                            required
                            onChange={(e)=>{
                                setAge(parseInt(e.target.value))
                            }}
                        />
                        <FormControl>
                            <FormLabel>Gender *</FormLabel>
                            <RadioGroup
                                row
                                aria-label="gender"
                                value={gender}
                                onChange={(e)=>{setGender(e.target.value)}}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="others" control={<Radio />} label="Others" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <TextField
                        label={"Phone"}
                        inputMode={"tel"}
                        multiline
                        fullWidth
                        type={"tel"}
                        style={{margin:"2% 0 5% 0"}}
                        required
                        onChange={(e)=>{
                            setPhone(e.target.value)
                        }}
                    /><br/>
                    <TextField
                        label={"Email (optional)"}
                        inputMode={"email"}
                        multiline
                        fullWidth
                        type={"email"}
                        style={{margin:"2% 0 5% 0"}}
                        onChange={(e)=>{
                            setEmail(e.target.value)
                        }}
                    /><br/>
                    <TextField
                        label={"Address"}
                        inputMode={"text"}
                        multiline
                        fullWidth
                        type={"text"}
                        style={{margin:"2% 0 5% 0"}}
                        required
                        onChange={(e)=>{
                            setAddress(e.target.value)
                        }}/><br/>
                    <TextField
                        label={"Issue"}
                        inputMode={"text"}
                        multiline
                        fullWidth
                        style={{margin:"2% 0 5% 0"}}
                        required
                        onChange={(e)=>{
                            setIssue(e.target.value)
                        }}/><br/>
                    <div className={"text-center"}>
                        <ReCAPTCHA
                            sitekey="6Lf78GEeAAAAAKM6hi09_RM8ir7bphY1h8ac5PEA"
                            onErrored={()=>{setHumanVerified(false)}}
                            onChange={onCaptchaChanged}
                        />
                    </div>
                    <div className={"bg-blue-800 rounded-md cursor-pointer shadow-lg p-4 m-4 mt-8 font-bold text-white hover:bg-blue-600 text-center"}
                    onClick={submitClicked}>
                        Submit Request
                    </div>
                </div>
            </div>
        </div>
    )
}
