import {getAuth} from "firebase/auth";

/**
 * Returns currently logged-in user.
 * Returns {null} is the use is not logged-in.
 * @returns {User}
 */
export const getAuthUser = ()=>{
    const auth = getAuth();
    return auth.currentUser
}
